.qr-page-body {
    overflow: hidden;
  
  }
  .qr-page-header {
    display: none !important;
  }
  .video-container section {
    height: 100vh;
  }
  .video-container section div{
    border: none !important;
    box-shadow: none !important;
  }
  .qr-page.btn_block {
    position: fixed;
    bottom: 10px !important;
    width: 100%;
    padding: 0 10px;
    z-index: 9999;
  }
  .qr-page.btn_block .btn_danger {
    width: 100%;
  }
  .qr-page-footer {
    display: none !important;
  }
  .disabled-btn {
    opacity: 0.5 !important;
  }
  #qr-shaded-region_1 {
    position: absolute;
    height: 30vh;
    background: rgba(0, 0, 0, 0.48);
    top: 35vh;
    left: 0px;
    width: 25vw;
  }
  
  #qr-shaded-region_1 .div-first {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 5px;
    height: 45px;
    top: 0vh;
    right: -5px;
  }
  #qr-shaded-region_1 .div-second {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 5px;
    height: 45px;
    bottom: 0vh;
    right: -5px;
  }
  
  #qr-shaded-region_2 {
    position: absolute;
    height: 30vh;
    background: rgba(0, 0, 0, 0.48);
    top: 35vh;
    right: 0px;
    width: 25vw;
  }
  #qr-shaded-region_2 .div-first {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 5px;
    height: 45px;
    top: 0;
    left: -5px;
  }
  #qr-shaded-region_2 .div-second {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 5px;
    height: 45px;
    bottom: 0vh;
    left: -5px;
  }
  
  #qr-shaded-region_3 {
    position: absolute;
    height: 35vh;
    background: rgba(0, 0, 0, 0.48);
    top: 0px;
    left: 0px;
    width: 100vw;
  }
  #qr-shaded-region_3 .div-first {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 45px;
    height: 5px;
    bottom: -5px;
    left: 25vw;
  }
  #qr-shaded-region_3 .div-second {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 45px;
    height: 5px;
    bottom: -5px;
    right: 25vw;
  }
  
  #qr-shaded-region_4 {
    position: absolute;
    height: 55vh;
    background: rgba(0, 0, 0, 0.48);
    top: 65vh;
    left: 0px;
    width: 100vw;
  }
  #qr-shaded-region_4 .div-first {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 45px;
    height: 5px;
    top: -5px;
  left: 25vw;
  }
  #qr-shaded-region_4 .div-second {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 45px;
    height: 5px;
    top: -5px;
    right: 25vw;
  }  