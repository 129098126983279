.custom-input-weekly-search {
  border-radius: 15px;
  border: 1px solid #c2c2c2;
  padding: 4px 12px;
  background-color: white;
  height: 50px;
  width: 250px;
}

.custom-input-weekly-search:focus {
  outline: none;
  border: 1px solid #850037;
  box-shadow: 0px 10px 15px -3px #0000001a;
}

/* mutli select react date pick ranger inbuild css changes */

/* Arrow icon css */
.rmdp-arrow {
  border: solid #93013d !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
  background-color: #93013d !important;
  box-shadow: 0 0 3px black !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid #ffffff !important;
  border-width: 0 2px 2px 0 !important;
}

/* Month and Year values */
.rmdp-header-values {
  color: #151b26 !important;
  font-weight: 600 !important;
}

/* Wekk Day Names css */
.rmdp-week-day {
  color: #93013d !important;
  font-weight: 700 !important;
}

/* Dates Specs css */
.rmdp-day {
  color: #151b26 !important;
  font-weight: 600 !important;
}

.rmdp-day.rmdp-today span {
  font-weight: 700 !important;
  background-color: #b9b9b9 !important;
}

.rmdp-day:not(.rmdp-day-hidden) span:hover {
  color: #850037 !important;
  font-weight: 700 !important;
  background-color: #d9d9d9 !important;
}

/* Next Month reflected Days in current month */
.rmdp-day.rmdp-deactive {
  color: #cacaca !important;
  font-weight: 800 !important;
}

/* Selecting week range css */
.rmdp-range {
  color: #ffffff !important;
  background-color: #93013d !important;
  box-shadow: 0 0 3px #93013d !important;
  font-weight: 700 !important;
  /* it has to be as secondary color*/
}


.rmdp-day.rmdp-selected span:not(.highlight) {
  color: #ffffff !important;
  background-color: #93013d !important;
  box-shadow: 0 0 3px #8798ad;
  font-weight: 700 !important;
}