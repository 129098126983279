#chi_preview .chi_preview_content {
    width: 100%;
    display: block;
}

#chi_preview .h3_title {
    line-height: 0px;
    padding: 0px 0px 3px 5px !important;
}

#chi_preview .cp_head_title {
    border-bottom: 1px solid #e9eaea;
    padding-bottom: 10px;
}

#chi_preview .chi_preview_content .cp_info {
    margin: 0;
    font-weight: 500;
    color: #566975;
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 1.5;
}

#chi_preview .chi_preview_content .sub_title {
    margin: 0;
    font-weight: 400;
    color: #566975;
    font-size: 21px;
    line-height: 1.5;
}

#aside_chi_preview .h6_title {
    margin-bottom: 10px;
}

#aside_chi_preview .status_title {
    text-transform: capitalize;
    font-weight: 600;
    color: #566975;
    margin-bottom: 25px;
    font-size: 16px;
}

#aside_chi_preview .status_result {
    background: #30c13d;
    padding: 10px 17px;
    display: inline-block;
    border-radius: 25px;
    color: #ffffff;
    font-weight: 600;
}

#aside_chi_preview .status_assign_title {
    color: #3990e8;
    font-weight: 600;
    font-size: 15px;
}

#aside_chi_preview .created_time {
    color: #566975;
}

#chi_preview .view_inspection_block {
    width: 100%;
    display: block;
    float: left;
}

.view_inspection_block .vi_btn {
    padding-left: 34px;
    padding-right: 34px;
    display: inline-block;
}