.float-right {
    float: right !important;
}
.history-pagination .pagination li a {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #850037;
    background-color: #fff;
    border: 1px solid #850037;
}
.history-pagination .pagination li a:hover {
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #850037;
}
.history-pagination .pagination li.active a {
    /* z-index: 1; */
    color: #fff !important;
    background-color: #850037;
    border-color: #850037;
}
.history-pagination .pagination li:last-child a {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.history-pagination .pagination  li:first-child a {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.history-pagination .pagination {
    float: right !important;
}
.qr-app-scroll-v2 .appcontent_Inner, .history-main .appcontent_Inner ,.qr-app-scroll .appcontent_Inner {
    padding-top: 0 !important;
}
.qr-page.btn_block {
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 0 10px;
    z-index: 9999;
    /*background: #fff;*/
}
.qr-page.btn_block .btn_danger {
    width: 100%;
}
.qr-page-footer {
    display: none !important;
}
.disabled-btn {
    opacity: 0.5 !important;
}
/*.desktop-view-history {*/
    /*display: block !important;*/
/*}*/
/*.mobile-view-history {*/
    /*display: none !important;*/
/*}*/
.mobile-view-history .input-modifier.css-2b097c-container{
    width: 25% !important;
    margin-left: 10px;
}

@media (max-width: 767px) {
    .desktop-btn-history {
        display: none !important;
    }
    /*.desktop-view-history {*/
        /*display: none !important;*/
    /*}*/
    .mobile-view-history .input-modifier.css-2b097c-container{
        width: 100% !important;
        margin-left: 0px !important;
    }
    .history_block .activity-feed .feed-item {
        padding-bottom: 5px !important;
    }
    .history_block .activity-feed {
        margin-top: 0 !important;
    }
}
/*.overflow-hidden {*/
    /*overflow: hidden !important;*/
/*}*/
/*@media screen and (max-width: 1075px) {*/
    /*.qr-app-scroll-v2 {*/
        /*overflow-y: auto;*/
        /*height: 100vh;*/
    /*}*/
/*}*/
@media screen and (min-width: 1075px) {
    .schedule-screen {
       margin-left: 230px;
    }
}
.top-0 {
    top: 0 !important;
}
.min-height-75 {
min-height: 80vh;
}
.left-0 {
    left: 0 !important;
 }
.schedule-page-min-height {
    min-height: calc(100vh - 275px);
}