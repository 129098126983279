div.galleryContainer {
    /* width: 95vw; */
    display: flex;
    justify-content: center;
    align-items: center;
}

div.gallery {
    margin: 5px;
    border: 1px solid #ccc;
    float: left;
    width: 500px;
}

div.gallery img {
    width: 100%;
    height: 100%;
}

.appvide {
    display: flex;
    align-items: center;
}

.appcanvas {
    position: absolute;
    /* top: 100px */
}

.myapp {
    display: flex;
    /* width: 100vw;
    height: 100vh; */
    flex-direction: column;
    align-items: center;
    margin: 5em;
    /* justify-content: space-between; */
}

.faceReg-segment .ant-segmented .ant-segmented-item-selected {
    color: #ffffff !important;
    background-color: #850037 !important;
}

.faceReg-segment .ant-segmented {
    color: #222222 !important;
    background-color: #b8b8b8 !important;
}

.file-input {
    display: none;
}

/* Style the label to look like a button */
.file-label {
    display: inline-block;
    padding: 10px 15px;
    background-color: #ffffff;
    color: #FFF;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: #1d1d1d;
}

.file-label:hover {
    background-color: #363636;
}


.text-v1 {
    font-size: 30px;
    font-weight: 500;
    font-family: sans-serif;
    color: #404040;
    margin-bottom: 20px;
}


/* SCANNER VIDEO TAG CSS */
.outline-container {
    position: relative;
    width: 640px;
    height: 360px;
    overflow: hidden;
}

video {
    width: 100%;
    height: 100%;
}

video::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    /* inherit color from parent to mask out properly */
    filter: blur(10px);
    mask: url(#yourSVGMask) center/contain no-repeat;
    pointer-events: none;
    /* ensure the overlay does not interfere with user interactions */
}

.face-outline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    /* make sure the SVG remains above the video */
}

.face-outline svg {
    max-width: 80%;
    max-height: 80%;
}


/* FACE SCANNER LOADER - FOR WAITING SCREEN */
.faceScannerWaitScreenloader {
    display: block;
    position: relative;
    height: 15px;
    width: 50%;
    border: 2px solid #850037;
    border-radius: 10px;
    overflow: hidden;
}

.faceScannerWaitScreenloader:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background: #850037;
    animation: 5.2s prog ease-in;
}

@keyframes prog {
    to {
        width: 100%;
    }
}

/* faceAnalyzerWaitScreenloaderText */
/* FACE ANALYZER LOADER TEXT - FOR WAITING SCREEN */
.faceAnalyzerWaitScreenloaderText {
    display: inline-block;
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #484f5d;
    position: relative;
}

/* .faceAnalyzerWaitScreenloaderText::before {
    content: '';
    position: absolute;
    left: 34px;
    bottom: 8px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 5px solid #000000;
    border-bottom-color: #FF3D00;
    box-sizing: border-box;
    animation: rotation 0.6s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */



/* FACE ANALYZER LOADER - FOR WAITING SCREEN */
.faceAnalyzerWaitScreenloader {
    width: 50px;
    height: 20px;
    display: block;
    margin: auto;
    position: relative;
    border-radius: 4px;
    color: #89073c;
    background: currentColor;
    box-sizing: border-box;
    animation: animloader 0.6s 0.3s ease infinite alternate;
}

.faceAnalyzerWaitScreenloader::after,
.faceAnalyzerWaitScreenloader::before {
    content: '';
    box-sizing: border-box;
    width: 50px;
    height: 20px;
    background: currentColor;
    position: absolute;
    border-radius: 4px;
    top: 0;
    right: 110%;
    animation: animloader 0.6s ease infinite alternate;
}

.faceAnalyzerWaitScreenloader::after {
    left: 110%;
    right: auto;
    animation-delay: 0.6s;
}

@keyframes animloader {
    0% {
        width: 20px;
    }

    100% {
        width: 48px;
    }
}


/* FACE SCANNER AND CAPTURED IMAGE HOLDING CARD CSS */
.faceRegAnal .ant-card {
    box-shadow: 0px 1px 10px 3px #8a8a8a69 !important;
}

/* MIRROR FOR FACE SCANNER */
.mirror-reflect-video {
    transform: scaleX(-1);
}