.justify-content-center {
  display: flex !important;
  justify-content: center;
}

.justify-content-space-between {
  display: flex !important;
  justify-content: space-between !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.align-items-center {
  align-items: center !important;
}

.flex-direction-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex_25 {
  flex: 0 0 25% !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.text-color-info {
  color: #007bff;
  font-weight: 600;
}

.text-color-warning {
  color: #e0a800;
  font-weight: 600;
}

.text-color-iridium {
  color: #3C3C3C !important;
}

.text-color-stormDust {
  color: #646464 !important;
}

.text-color-davyGrey {
  color: #4f5a5e !important;
}

.text-color-pastelRed {
  color: #FF6961 !important;
}

.text-color-lightCarminePink {
  color: #E7625F !important;
}

.text-color-fuzzyWuzzyBrown {
  color: #C85250 !important;
}

.text-color-shiraz {
  color: #BA0F30 !important;
}

.text-color-pastelBlue {
  color: #AEC6CF !important;
}

.text-color-pastelYellow {
  color: #FDFD96 !important;
}

.text-color-pastelGreen {
  color: #77DD77 !important;
}

.pegdesk_button {
  background-color: #850037 !important;
  color: white !important;
  font-weight: 700 !important;
}

.pegdesk_button:hover {
  background-color: #9b2c59 !important;
}

.success_button {
  background-color: #00a800 !important;
  color: white !important;
  font-weight: 700 !important;
}

.success_button:hover {
  background-color: #00bd00 !important;
}

.danger_button {
  background-color: #cb002c !important;
  color: white !important;
  font-weight: 700 !important;
}

.danger_button:hover {
  background-color: #e40031 !important;
}

.warning_button {
  background-color: #fdfd96 !important;
  color: #4a3f3f !important;
  font-weight: 700 !important;
}

.warning_button:hover {
  background-color: #fdfd96 !important;
}

.width_50 {
  width: 50% !important;
}

.width_80 {
  width: 80% !important;
}

.width_100 {
  width: 100% !important;
}

.max_width_100 {
  max-width: 100% !important;
}

.max_width_200 {
  max-width: 100% !important;
}

.mw-50per {
  max-width: 50% !important;
}

.min_width_50 {
  min-width: 50% !important;
}

.min_width_150 {
  min-width: 150% !important;
}

.fontSize_xxlarge {
  font-size: xx-large !important;
}

/* Graph title */
.empty-graph .chart_title2,
.empty-graph_heading .chart_title2 {
  color: #962d39 !important;
  font-weight: 700 !important;
  margin-top: 30px;
  text-align: center;
}

.empty-graph {
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.empty-graph .chart-img {
  margin-top: 0 !important;
}

.app-menu__icon {
  width: auto !important;
}

/* Disabled SignIn Button */
.button_Disable {
  pointer-events: none;
  opacity: 0.7;
}

.work_order_block .w_order_media .btn_block .cst_btn {
  margin-right: 8px !important;
}

.quotes_btn_wrap {
  margin-top: 0px;
}

/* Error Styles */
.errorStyles {
  border-color: rgb(240, 77, 83) !important;
  border-left: 3px solid #f04d53 !important;
}

.inputStyles {
  border-color: #c2c2c2 !important;
}

.addQuote_form .btn,
.search_filter_cont .btn {
  min-height: 36px;
}

/* React Table Styles */
.react-td-element-editSale {
  border-top: none;
  vertical-align: middle;
  font-size: 14px;
  margin-top: 7px !important;
  white-space: unset !important;
  color: rgb(43, 48, 52);
  text-align: center;
  flex: 100 0 auto;
  width: 100px;
  background-color: #e458654f;
}

.react-td-element {
  border-top: none;
  vertical-align: middle;
  font-size: 14px;
  margin-top: 7px !important;
  white-space: unset !important;
  color: rgb(43, 48, 52);
  text-align: center;
  flex: 100 0 auto;
  width: 100px;
}

.react-td-element-status {
  border-top: none;
  vertical-align: middle;
  font-size: 14px;
  margin-top: 5px !important;
  white-space: unset !important;
  color: rgb(43, 48, 52);
  text-align: center;
  flex: 100 0 auto;
  width: 120px !important;
}

.react-table-tr-element:hover {
  border: 1px solid rgb(131, 62, 128) !important;
  background-color: rgba(131, 62, 128, 0.068);
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  margin-bottom: 6px;
}

.-even {
  background-color: #ebf0f78c;
}

/* Sorting Shadow color */
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none !important;
}

.icon-asc,
.icon-desc {
  display: none;
}

.-sort-asc .rt-resizable-header-content .icon-asc {
  display: inline-block !important;
  height: 11px !important;
}

.-sort-desc .rt-resizable-header-content .icon-desc {
  display: inline-block !important;
  height: 11px !important;
}

.-sort-desc span {
  /* margin-top: 2px;
     */
  /* box-shadow: inset 0 -3px 0 0 rgba(0,0,0,0.6);
     */
}

.-sort-asc span {
  /* margin-bottom: 2px;
     */
  /* box-shadow: inset 0 3px 0 0 rgba(0,0,0,0.6);
     */
}

/* FILTER STYLING */
.ReactTable .rt-thead.-filters {
  border-bottom: none !important;
}

/* Sorting Shadow color */
/* React-Table Pginations */
.pagination-bottom {
  float: left;
  width: 100%;
}

.ReactTable .-pagination {
  font-size: 15px;
  box-shadow: none !important;
  border-top: none !important;
  display: block !important;
  margin-top: 0px !important;
  padding: 0 !important;
}

.ReactTable .-pagination .-btn {
  background: #fff !important;
  border: none;
  color: #50649c !important;
  cursor: pointer !important;
  border: 1px solid #ddd !important;
  padding: 7px 10px !important;
  min-width: 85px;
  width: auto !important;
  opacity: inherit !important;
  border-radius: 0 !important;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  float: right;
}

.ReactTable .-pagination .-next .-btn {
  border-right: 0px !important;
  border-radius: 4px 0 0px 4px !important;

}

.ReactTable .-pagination .-previous .-btn {
  border-radius: 0 4px 4px 0 !important;
}

.ReactTable .-pagination .-next .-btn:hover,
.ReactTable .-pagination .-next .-btn:focus,
.ReactTable .-pagination .-previous .-btn:hover,
.ReactTable .-pagination .-previous .-btn:focus {
  color: #89073c !important;
  outline: none;
  background: #f1f5fa !important;
}

.ReactTable .-pagination select {
  padding: 7px 5px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px;
  width: 100px;
  margin: 0 0px;
  text-align: center;
  margin-left: 1px !important;
  background-image: url(/images/background/select_down_arrow.png) !important;
  background-repeat: no-repeat !important;
  background-position: 90% 17px !important;
  background-size: 12px !important;
  cursor: pointer !important;
}

.css-2b097c-container {
  cursor: pointer !important;
}

.ReactTable .-pagination .-center {
  display: block !important;
  float: left;
  color: #50649c;
}

.ReactTable .-pagination input {
  width: 20px !important;
  text-align: left;
  border: none !important;
  margin: 0 !important;
  font-weight: bold !important;
  color: #89073c;
  pointer-events: none !important;
  padding: 0 !important;

}

.ReactTable .-pagination .-pageInfo {
  margin: 3px 0 !important;
}

/* React-Table Pginations */
/* css for status buttons */
.btn_approved {
  background: #f1ad308c;
  color: #d28500;
  font-weight: 500;
  min-width: 95px;
}

.btn_approved:hover {
  box-shadow: none !important;
  transform: inherit;
  cursor: auto !important;
}

.btn_request {
  background: rgba(48, 193, 61, 0.15);
  color: #30c13d;
  min-width: 95px;
}

.btn_request:hover {
  box-shadow: none !important;
  transform: inherit !important;
  cursor: auto !important;
}

.btn_NA {
  background: #c2c2c2;
  color: #ffffff;
  min-width: 95px;
}

.btn_NA:hover {
  box-shadow: none !important;
  transform: inherit !important;
  cursor: auto !important;
}

.search_filter_cont .cst_btn {
  padding: 0.45rem 0.75rem;
}

/* css for buttons */
.edit_profile_modal .modal-footer .cst_btn {
  min-width: 120px;
  min-height: 36px !important;
}

.limiter .inner_form .btn_lg {
  min-height: 60px;
}

.work_order_block .w_order_media .btn_block .cst_btn {
  min-height: 36px;
}

.add_account_modal .cst_btn {
  min-height: 36px;
}

.quotes_table_content .cst_btn {
  height: auto;
  line-height: inherit;
  padding: 8px 12px;
}

.quotes_table_content .btn .fa {
  vertical-align: baseline;
}

.out_line_link {
  min-height: 36px;
  float: left;
}

#wrap_register .inner_form .btn_lg {
  min-height: 63px;
}

.MuiTypography-root.MuiTypography-h6 h3 {
  font-size: 20px;
}

.MuiDialogActions-root {
  justify-content: center !important;
  -webkit-justify-content: center !important;
}

.MuiDialogActions-root {
  padding: 8px 0px 15px !important;
}

/* dashboard CSS Here  */
.wraper_content .filter_block .list_filter .list_link {
  padding: 0px !important;
  width: 61px;
  height: 42px;
  line-height: 42px;
  text-align: center;
}

.wraper_content .cal_block {
  float: right !important;
}

.wraper_content .filter_block .list_filter {
  display: flex !important;
  display: -webkit-flex !important;
}

.wraper_content .filter_block .list_filter .active:first-child,
.wraper_content .filter_block .list_filter .list_link:hover:first-child {
  border-radius: 4px 0 0px 4px;
}

.wraper_content .filter_block .list_filter .active:last-child,
.wraper_content .filter_block .list_filter .list_link:hover:last-child {
  border-radius: 0 4px 4px 0;
}

.admin_dashboard .calc_content li:hover:first-child {
  border-radius: 4px 0 0px 4px;
}

.admin_dashboard .calc_content li:hover:last-child {
  border-radius: 0 4px 4px 0;
}

/* table desgin CSS Here */
.rt-resizable-header-content span {
  font-size: 14px;
  font-weight: 700;
  color: #344654;
}

.ReactTable .rt-tbody {
  overflow: hidden !important;
}

.ReactTable .rt-tr {
  align-items: center !important;
}

.ReactTable .react-td-element {
  margin-top: 0px !important;
}

/**CSS Here* 23-07-2019**/
.ReactTable .rt-tbody .rt-td {
  border-right: none !important;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none !important;
  margin-bottom: 2px;
}

.app-menu__item {
  border-left: 4px solid transparent !important;
}

.app-menu__item.active,
.app-menu__item:hover,
.app-menu__item:focus {
  background: transparent !important;
  border-left: 4px solid #89073c !important;
}

.edit_profile_modal .profile_tab .circle_img,
.add_account_modal .circle_img {
  line-height: 100px;
  border-radius: 50%;
  background: transparent !important;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  overflow: hidden;
  object-fit: cover;
  text-align: center;
  position: relative;
}

.profile_changes_pic .user_title,
.add_account_modal .user_title {
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  font-size: 38px;
  z-index: 1;
  display: none;
}

.profile_changes_pic .icn_userEdit,
.add_account_modal .icn_userEdit {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  z-index: 11;
  font-size: 17px;
  opacity: 0;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.profile_changes_pic .icn_userEdit a,
.add_account_modal .icn_userEdit a {
  color: #fff;
}

.profile_changes_pic .user-profile-pic:hover .icn_userEdit,
.add_account_modal .user-profile-pic:hover .icn_userEdit {
  opacity: 1;
}

.edit_profile_modal .profile_tab .circle_img img,
.add_account_modal .circle_img img {
  width: 100%;
  height: 100% !important;
  display: block;
}

.profile_changes_pic .close {
  color: gray;
  font-weight: 400;
  border: none !important;
  width: auto !important;
  height: auto !important;
  opacity: inherit !important;
  line-height: inherit !important;
  position: relative;
  top: -12px;
}

.profile_changes_pic .close:hover,
.profile_changes_pic .close:focus {
  outline: none;
  opacity: 0.5 !important;

}

.profile_changes_pic .cst_nav_tab {
  padding: 7px 30px 0 30px !important;
}

.profile_changes_pic .modal-header {
  padding: 0px 0px 10px !important;
}

.app-search__button i {
  color: #a9b3bc;
  font-size: 15px;
}

.account_edit_icn i {
  font-size: 20px;
  cursor: pointer;
  vertical-align: middle;
}

.account_crown_icn_active {
  color: #faaa0a !important;
}

.account_crown_icn_active i:hover,
.account_crown_icn_active i:focus {
  color: #faaa0a !important;
}

.account_edit_icn i:hover,
.account_edit_icn i:focus {
  color: #89073c;
}

.prior-ticket-color .account_edit_icn i:hover,
.account_edit_icn i:focus {
  color: #fff;
}

.account_crown_icn i:hover,
.account_crown_icn i:focus {
  color: #faaa0a !important;
}

.reject_quot .modal-header {
  padding: .85rem 1.5rem .50rem !important;
}

.reject_quot .cst-label {
  margin-bottom: -1px !important;
}

.request_quote_modal .close {
  margin: -0.99rem -.85rem -1rem auto !important;
}

.approve_Quotes_sec {
  margin-top: 60px;
}

.approve_Quotes_sec {
  margin-top: 70px;
}

.approve_Quotes_sec .work_order_block .circle_img {
  border: none;
  width: 50px;
  height: 50px;
  background-color: transparent;
}

.approve_Quotes_sec .work_order_block .circle_img img {
  width: 50px !important;
  height: 50px !important;
}

.approve_Quotes_sec .inner_content {
  padding: 20px;
}

.approve_Quotes_sec .header_block .contact_info {
  margin-top: 0px;
}

.approve_Quotes_sec .work_order_block .md_title {
  font-size: 30px;
}

.approve_Quotes_sec .work_order_block {
  padding: 20px 25px 20px;
}

.approve_Quotes_sec .work_order_block .w_order_media .media_title {
  font-size: 25px;
}

.approve_Quotes_sec .work_order_block .w_order_media .text_gray,
.approve_Quotes_sec .work_order_block .w_order_media .text_gray {
  color: #990d46;
  opacity: inherit;
  font-weight: 600;
}

.approve_Quotes_sec .work_order_block .w_order_media .media_title {
  margin-top: 7px;
}

.approve_Quotes_sec textarea {
  resize: none;
  min-height: 85px;
}

.edit-input {
  background-size: 11px !important;
  padding-left: 20px !important;
}

.pagination-top {
  float: left;
  width: 100%;
  margin-bottom: 16px;
}

.search_filter_cont .app-search__button {
  top: 7px !important;
}

.pagination-bottom {
  margin-top: 20px;
}

.chart_section .admin_dashboard .tile_block h2 {
  font-size: 25px;
}

.chart_section .admin_dashboard .chart-content .tile:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  -webkit-transition: 0.3s all;
}

.app-sidebar .app-menu__item .app-menu__icon {
  transition: inherit;
}

#editProfile .custom-select,
.appcontent_Inner .search_filter_cont .custom-select,
.add_New_Accont .custom-select.add_user_select {
  background-size: 12px !important;
}

.wraper_content .filter_block .list_filter .list_link {
  font-size: 14px !important;
}

.admin_dashboard .calc_content li {
  font-size: 14px;
}

.MuiDialogTitle-root {
  padding: 13px 60px 8px !important;
}

.btn_approved:hover {
  transform: inherit !important;
}

.app-header .user_menu_dropdown .user_circle {
  background: transparent !important;
}

.ReactTable .rt-noData {
  position: static !important;
  background: rgba(255, 255, 255, 0.8);
  pointer-events: none;
  padding: 20px !important;
  color: #151b26 !important;
  text-align: center;
  font-size: 18px;
  margin: 0 auto;
  transform: translate(0%, 0%) !important;
  margin-top: 60px;

}

.edit_profile_modal .profile_tab .circle_img {
  background: transparent;
}

.-pagination .-totalPages {
  margin-left: 7px;
}

.clap__Wrapper-sc-12p9tb5-0.duAduc {
  margin: 50px auto;
}

/* .quotes_table_content .btn-outline-secondary i{
 margin-top: -4px !important;
} */
.work_order_block .w_order_media,
.work_order_block .cst_para {
  margin-bottom: 21px !important;
}

.newaccoubnt_profile .profile-details {
  padding-bottom: 18px;
}

.react_times_button.time_picker_preview {
  width: auto !important;
  box-shadow: none !important;
  height: auto !important;
  float: right;
  padding: 20px 0;
  margin-right: 15px;
}

.react_times_button.time_picker_preview .preview_container.without_icon {
  /* box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 15px 0px; */
  padding: 10px 15px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: .5 !important;
  cursor: default !important;
  color: #50649c !important;
}

.ReactTable .-pagination .-btn[disabled]:hover,
.ReactTable .-pagination .-btn[disabled]:focus {
  color: #50649c !important;
  background: transparent !important;
}

.table-responsive {
  overflow-x: visible !important;
}

.quotes_table_content .btn_request,
.quotes_table_content .btn_approved {
  padding: 6px 12px 8px;
}

.chart_cont_box {
  position: relative;
}

.char_empty_cart {
  position: absolute;
  top: 40%;
  left: 0;
  text-align: center;
  width: 100%;
  display: block;
}

.char_empty_cart span {
  font-weight: 600;
  margin-top: 15px;
  display: block;
}

/* .dropdown-item{
    background-color: #2f3a46;
    width: 180px;
    position: absolute;
    left: 0;background-color: gray;
    width: 218px;
    position: absolute;
    left: 50px;
    margin-left: -39px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}*/

.app-menu__item.settings_menu.active,
.app-menu__item:hover,
.app-menu__item:focus {
  background: #2f3a46 !important;
  border-left-color: #2f3a46 !important;
  text-decoration: none;
  color: #fff;
}

/**End CSS Here* 23-07-2019**/
/*End table desgin CSS Here */
/* side bar toggole navigation */
@media(min-width:768px) {

  /**CSS Here* 23-07-2019**/
  .sidebar-mini.sidenav-toggled .app-menu__label {
    display: none;
  }

  .sidenav-toggled .app-menu__item {
    padding: 8px 9px;
  }

  .sidebar-mini.sidenav-toggled .app-header__logo {
    padding-top: 15px !important;
    padding-bottom: 0 !important;
  }

  /**CSS Here* 23-07-2019**/
  .app-header__logo {
    -webkit-transition: left 0.3s ease, width 0.3s ease;
    -moz-transition: left 0.3s ease, width 0.3s ease;
    -o-transition: left 0.3s ease, width 0.3s ease;
    transition: left 0.3s ease, width 0.3s ease !important;


  }

  header.app-header:before {
    -webkit-transition: margin-left 0.3s ease, width 0.3s ease;
    -moz-transition: margin-left 0.3s ease, width 0.3s ease;
    -o-transition: margin-left 0.3s ease, width 0.3s ease;
    transition: margin-left 0.3s ease, width 0.3s ease !important;
  }

  .app_header_block {
    padding: 8px 15px !important;
  }

  .app-sidebar__toggle {
    margin-top: 0px;
  }

  .app_title {
    padding: 17px 0 17px 23px !important;
  }

  /*.cus_ffoter_block{*/
  /*width: calc(100% - 230px) !important;*/
  /*margin-left: 230px !important;*/
  /*}*/
  .toggle-sideBar .cus_ffoter_block {
    width: calc(100% - 50px) !important;
    margin-left: 50px !important;
  }

  /*   body.sidenav-toggled footer.cus_ffoter_block {
        width: 100% !important;
        margin-left: 0 !important;
      } */
}

.sidenav-toggled .setting_dropdown .cst_accordion_menu .card-header a::after {
  content: none;
}

.sidebar-mini .sidenav-toggled .setting_dropdown .cst_accordion_menu .collapse {
  display: none !important;
}

.sidebar-mini .sidenav-toggled #collapseOne {
  display: none;
}


.csv_btn {
  cursor: pointer;
  border: none;
  padding: 8px 22px;
  margin-bottom: 10px;
  border-radius: 4px;
  letter-spacing: 1.2px;
  float: left;
  text-decoration: none !important;
}


.account_sec .quotes_table_content .cst_btn.btn_request {
  color: #30c13d !important;
}

.account_sec .quotes_table_content .cst_btn.btn_approved {
  color: #d28500 !important;
}

.loader_btn_block {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 80vh;
}

.search_filter_cont .app-search {
  padding-bottom: 0;
}

.app-search__button {
  bottom: inherit;
}

.flex_box {
  align-items: center;
}

.admin_dashboard .tile_block h2 {
  margin-top: 0;
}

.admin_dashboard .chart-content .tile {
  padding: 20px 20px 18px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.ins_detail .select_modifier {
  opacity: 1 !important;
}

.attachments__file_box {
  position: relative;
}

.attachments__file_box .cancel_icon {
  position: absolute;
  z-index: 2;
  top: 4px;
  right: 4px;
  background: #868686;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  overflow: hidden;
  padding: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.attachments__file_box .cancel_icon img {
  width: 100%;
  display: block;
  object-fit: cover;
}

.file_attachment .file_view {
  margin-bottom: 10px;
}

.edit_inspection .tickets_user_profile_body .user_activity_conent .attachment_sec .attachments_files {
  margin-bottom: 10px;
}

.setting_dropdown .cst_accordion_menu .dropdown-item.active {
  background-color: #2f3a46;
}

.setting_dropdown .dropdown-item.active,
.dropdown-item:active {
  background-color: #2f3a46 !important;
}

.edit_rating .btn_block .cst_btn {
  min-height: 45px;
}

.list_tab_content .rating_info .input-group-text {
  height: 55px !important;
}

header.app-header:before {
  bottom: -1px !important;
}

.edit_inspection .rating_form_card .card_footer {
  padding: 1.25rem 1.95rem 1.25rem;
}

.edit_inspection .setting_form_con .card_footer .btn_block .cst_btn {
  min-height: 45px;
}

.settings .setting_form_con .edit_info_form .rating_info .input-modifier {
  height: 63px;
}

.form_info .css-yk16xz-control {
  min-height: 45px;
}

.performance_modal .cst_btn {
  min-height: 45px;
}

.appcontent_inner .quotes_btn_wrap {
  text-align: left;
}

.appcontent_inner .app-search__button {
  bottom: inherit;
}

.approve_Quotes_sec {
  padding-top: 65px;
}


.setting_form_con .edit_info_form .input-modifier {
  border: 1px solid #ced4da;
}

.settings .setting_form_con .edit_info_form .rating_info .input-modifier {
  height: 45px;
}

.setting_form_con .edit_info_form .input-modifier {
  background-color: #fff;
}

.form-control.input-modifier.bg_gray.css-2b097c-container {
  border: none;
  width: 100%;
  padding: 0;
}

.css-yk16xz-control {
  min-height: 45px;
}

.setting_form_con .rating_info .card {
  background-color: transparent;
  border: 1px solid #ced4da;
}

.setting_form_con .rating_info .icn_block {
  padding: .8rem .8rem .8rem !important;
  height: 45px !important;
}

.setting_form_con .edit_info_form .rating_info .css-yk16xz-control {
  min-height: 45px !important;
  border-color: #ced4da;
}

.css-1okebmr-indicatorSeparator {
  background-color: #ced4da;
}

.css-tlfecz-indicatorContainer {
  color: #ced4da;
}

/*rating screen css*/
.setting_form_con .edit_info_form .input-modifier {
  height: 45px !important;
}

.edit_rating .radius_checkbox.checkbox_block .custom-control-label::before {
  top: -1.1rem !important;
}

.edit_rating .radius_checkbox .custom-control-label::after {
  top: -1.2rem !important;
}

.cst_radio_btn .custom-control-label::before {
  top: -1.2rem !important;
}

.cst_radio_btn .custom-control-input:checked~.custom-control-label::after {
  top: -0.85rem !important;
  left: -19px !important;
}

.edit_profile_modal .modal-footer .cst_btn {
  min-height: 45px !important;
}

.setting_form_con .rating_info .icn_block .cst_icon {
  margin-right: 0 !important;
}

.setting_form_con .css-1hwfws3 {
  min-height: 45px;
}

.setting_form_con .rating_info .delete_icn_block {
  border: none;
}

.setting_form_con .rating_info .cst_radio_btn .custom-control-label::after {
  top: -1.2rem;
}

.setting_form_con .rating_info .custom-control-label {
  cursor: pointer;
}


.add_account_modal .cst_btn {
  min-height: 45px;
}

.user_newQuote .inspection_content_sec .btn_block .btn {
  min-height: 45px;
}

.setting_form_con .rating_info .card {
  border: none;
}

.setting_form_con .rating_form_card .card_footer .btn_block {
  margin: 0 0 0 !important;
}

.edit_inspection .rating_form_card .card_footer {
  padding: 1.25rem 0 1.25rem;
}

.edit_inspection .rating_form_card .card_footer .btn_block {
  text-align: center !important;
}

.list_tab_content .rating_info .input-group-text {
  height: 45px !important;
}

form.tickets_view_form {
  width: 100%;
}

.file-upload-wrapper {
  cursor: pointer !important;
  height: 20px !important;
}

.ins_detail .btn_block .btn_danger {
  margin-right: 10px !important;
}

.ins_detail .ins_detail_block .card-body {
  padding: 1.25rem 1.25rem 1.25rem !important;
}

.file-upload-wrapper input {
  height: -20px;
  cursor: pointer;
  pointer-events: painted;
}

#total_sale.edit-input {
  margin-top: 5px;
}

.export_csv_block {
  margin-bottom: 20px;
}

.export_csv_block label {
  width: 100%;
}

.dashboard_page_body .appcontent_Inner {
  padding: 25px 20px;
}

.ReactTable {
  z-index: 0;
}

.input-group-append.cst_group_append {
  position: absolute;
  right: 15px;
  height: 36px;
  top: 0;
  /* display:inline-block; */
}

.input-group-append .input-group-text {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.app-sidebar {
  overflow: inherit;
}

.account_edit_icn i {
  margin-right: 0 !important;
}


@media (min-width: 992px) {
  .modal-lg {
    max-width: 1000px !important;
  }
}

/** 15/11/19 **/
.close_btn {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
}

.close_btn .fa-times-circle {
  font-size: 27px;
  color: #c8c8c8;
}

.img_gallery_modal .image-gallery-image img {
  height: 600px;
  object-fit: contain !important;
  background-color: #000000cc;
}

.img_gallery_modal .modal-body {
  padding: 0;
}

.export_csv_block .react-datepicker-wrapper .input-modifier {
  width: calc(100% - 35px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.export_csv_block .input-group-text {
  width: 35px;
  display: inline-block;
  border-left: 0;
}

.imageloader.loaded.room_img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-bottom: 10px;
}

.imageloader.loaded.room_img img {
  width: 100%;
  height: auto;
}

.quote_form .cst_tab_content .custom-control-label::after {
  cursor: pointer;
}

.ticket_form .custom-control-label::after {
  cursor: pointer;
}

.ticket_form .css-yk16xz-control {
  border-color: #ced4da;
  border-radius: 0.25rem;
  min-height: 45px;
}

.ticket_form .css-yk16xz-control:hover {
  border-color: #8d8d8d;
}

.reject_quot .modal-header {
  padding: .85rem 1.5rem .85rem !important;
}

.reject_quot .edit_profile_modal .modal-footer {
  padding-top: 22px;
  padding-bottom: 28px;
}

.performance_modal .checkbox1 .custom-checkbox {
  top: 0 !important;
}

.performance_modal .checkbox1 .cst_label_txt {
  position: inherit;
  top: 0;
  left: 0;
}

.treeview-menu {
  background: #151b26 !important;
}

.treeview.is-expanded [data-toggle='treeview'] {
  /* border-left-color: #89073c !important; */
  background: transparent;
}

.treeview-item.active,
.treeview-item:hover,
.treeview-item:focus {
  background: #2f3a46 !important;
  text-decoration: none;
  color: #fff;
}

.prior_ticket_tab_color,
.prior_ticket_tab_color:hover,
.prior_ticket_tab_color:active {
  background-color: #850037 !important;
  text-decoration: none;
  color: #fff;
}

.treeview-menu {
  max-height: 100vh;
  overflow: hidden;
  transition: max-height .3s ease;
  background: #000000 !important;
  transition: transform 0.5s ease !important;
  transform: translateY(-100%);
  opacity: 0;
}


.treeview.is-expanded .treeview-menu {
  max-height: 100vh;
  transform: translateY(0);
  opacity: 1;
}

.treeview-item {
  padding: 5px 5px 8px 20px !important;
}

.treeview-item-prior-ticket {
  padding: 5px 5px 8px 20px !important;
}

.treeview.is-expanded .treeview-indicator {
  -webkit-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}

.user_profile_details {
  height: 100%;
}

.tickets_user_profile_body .border_user_profile::after {
  height: 100% !important;
}

.app-sidebar {
  overflow: inherit !important;
}

.user_profile_details .user_profile_details_sec .assign_location .assigned_to_div label {
  white-space: inherit !important;
}


.tickets_user_profile_body .people_notify_others .use_hashtag p {
  margin-top: 10px;
}

.treeview-menu {
  -webkit-transition: max-height 0.8s ease-in-out !important;
  -moz-transition: max-height 0.8s ease-in-out !important;
  -o-transition: max-height 0.8s ease-in-out !important;
  transition: max-height 0.8s ease-in-out !important;
}

.treeview-indicator {
  transition: transform 0.5s ease-in-out !important;
  -webkit-transition: transform 0.5s ease-in-out !important;
  -moz-transition: transform 0.5s ease-in-out !important;
  -o-transition: transform 0.5s ease-in-out !important;
}

.edit_inspection .card_footer .btn_block .cst_btn {
  min-height: 45px;
}

.tickets_user_profile_body .user_activity_conent .attachment_sec .attachments_files {
  border-bottom: none !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.user_profile_details .user_profile_details_sec {
  margin-top: 20px !important;
}

.tickets_user_profile_body .tickets_user_activity {
  padding: 15px 0 15px !important;
}

.tickets_user_profile_body .user_activity_conent .tickets_users_comments .sent_comments .tickets_user_bottomSec .user_comment_area .comment_file_attachment {
  padding-top: 13px !important;
  margin-bottom: 0 !important;
}

.tickets_user_profile_body .user_activity_conent .attachment_sec .attachment_title .attachment_icn {
  cursor: pointer !important;
}

.user_profile_details .user_profile_details_sec .user_status {
  padding: 20px 0 20px !important;
}

.user_profile_details .user_profile_details_sec .assign_location .user_location_box h6 {
  min-height: 55px;
}

.user_profile_details .user_profile_details_sec .assign_location .assigned_to_div span {
  min-height: 55px;
}

.assign_location .gutter {
  padding-right: 0 !important;
}

.datePicketError {
  display: block !important;
  border-color: #f04d53 !important;
  border-left: 3px solid #f04d53 !important;
}

.user_newQuote .inspection_content_sec .addQuote_form #total_sale {
  background-repeat: no-repeat;
  background: url(/images/background/icn_dollar.png) no-repeat left 0.3rem center !important;
  background-size: 12px !important;
  padding-left: 22px !important;
}

.file_attachment .file_view img {
  object-fit: cover !important;
}

.tickets_user_profile_body .comment_buttons .cst_btn {
  min-height: 40px;
}

.cst_btn {
  padding: 0.55rem 0.75rem;
  min-width: 100px;
  min-height: 40px;
}


/* React-Table TicketStatus Code(Added by Shravan) */

.react-table-tr-element-ticket:hover {
  border-top: 1px solid rgb(131, 62, 128) !important;
  border-right: 1px solid rgb(131, 62, 128) !important;
  border-bottom: 1px solid rgb(131, 62, 128) !important;
  background-color: rgba(131, 62, 128, 0.068);
}

.react-table-tr-element-open-status {
  border-left: 4px solid #c9302c !important;

  /* border: 1px solid rgb(131, 62, 128) !important;
    background-color: rgba(131, 62, 128, 0.068); */
}

.react-table-tr-element-open-status-brm{
  border-left: 4px solid #19BF3A !important;
}


.react-table-tr-element-pending-status {
  /* border: 1px solid rgb(131, 62, 128) !important; */
  border-left: 4px solid #ec9720 !important;
  /* background-color: rgba(131, 62, 128, 0.068); */
  /* border: 1px solid rgb(131, 62, 128) !important;
    background-color: rgba(131, 62, 128, 0.068); */
}


.react-table-tr-element-close-status {
  /* border: 1px solid rgb(131, 62, 128) !important; */
  border-left: 4px solid #449d44 !important;
  /* border: 1px solid rgb(131, 62, 128) !important;
    background-color: rgba(131, 62, 128, 0.068); */
}

.user_profile_details .user_profile_details_sec .assign_location .user_location_box h6 {
  margin-bottom: 0;
}

.user_profile_details .user_profile_details_sec .assign_location .user_location_box .assignTo {
  min-height: auto;
}

.user_profile_details .user_profile_details_sec .priority_id {
  margin-top: 10px !important;
}

.app-sidebar {
  height: 100%;
}

.app-menu {
  height: 100%;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 50px;
}

.image-gallery-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal.associateAccount .select_style_links:focus,
.modal.associateAccount .select_style_links:hover {
  background-color: transparent !important;
  border: 1px solid #566975 !important;
  color: #566975 !important;
}

.modal.associateAccount .select_style_links.active {
  background-color: #850037 !important;
  border-color: #850037 !important;
  color: #FFF !important;
}

/* React DatePicker in Quotes */

.export_csv_block .react-datepicker-wrapper .input-modifier {
  width: calc(100% - 28px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.export_csv_block label {
  display: flex;
}

.last_activity label {
  margin-right: 10px;
}

.dashboard_page_body .ticket_user .appcontent_Inner {
  padding: 25px 15px;
}

.user_profile_details .user_profile_details_sec .created_lastActivity .created h6,
.user_profile_details .user_profile_details_sec .created_lastActivity .last_activity h6 {
  margin-bottom: 20px;
}

.user_profile_details .user_profile_details_sec .assign_location .user_location_box .assignTo {
  /* margin-bottom: 20px; */
}

.user_profile_details .user_profile_details_sec .assign_location .assigned_to_div span {
  color: #566975;
}

.user_profile_details .user_profile_details_sec .assign_location .assigned_to_div label {
  color: #566975;
}




/* @since: 08-01-2020*/
.user_profile_details .user_profile_details_sec .assign_location .assigned_to_div {
  margin-top: -5px !important;
}

.room_img_block .imageloader {
  margin-bottom: 15px;
}

.room_img_block .imageloader img {
  width: 100% !important;
  height: 100% !important;
  margin-right: 0 !important;
}

.image-gallery-slide-wrapper .image-gallery-left-nav,
.image-gallery-slide-wrapper .image-gallery-right-nav {
  outline: none;
}

.image-gallery-image {
  /* width: 600px !important;
    height: 400px !important;
    display: block !important;
    margin: 0 auto !important; */
}

/*End of css @since: 08-01-2020*/

/* inspection_content_block section start here   this css add by sugumar.P  */
.overall_score {
  width: 100%;
  display: block;
  margin-top: 15px;
}

.overall_score .inspections {
  width: 100%;
  display: block;

}

.inspection_content_block {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 12px;
}

.inspection_content_block:first-child {
  margin-top: 0px;
}

.inspection_content_block .left_list {
  display: inline-block;
  width: 100%;
  min-width: 235px;
  max-width: 235px;
  text-align: right;
  margin-right: 15px;
}

.inspection_content_block .left_list .h5_title {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  text-transform: inherit;
  color: #151b26;
}

.inspection_content_block .right_list {
  display: block;
  width: calc(100% - 235px);
  margin-left: auto;
}

.inspection_content_block .right_list .inspection_info {
  margin: 0px;
  color: #696565;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.map_part {
  width: 100%;
  display: block;
  height: 100%;
  text-align: right;
}

.map_part .map_inner {
  width: 350px;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  margin: 0;
  min-height: 200px;
  max-height: 200px;
}

.map_part .map_inner .map_image {
  width: 100%;
  display: block;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;

}

/* inspection_content_block section end here */



/* perform inspection model section start here */

.create_quote_btn {
  width: 100%;
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
}

.create_quote_btn .btn_danger {
  background-color: #9b2c59 !important;
  border-color: #9b2c59 !important;
}

.create_quote_text {
  width: 100%;
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
}


.create_quote_text .after_popup_text {
  margin: 0;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  color: rgb(137, 7, 60);
  background-color: rgba(233, 236, 239, 0.35);
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
}



.search_filter_cont .quotes_btn {
  margin-top: 15px;
}

.admin_quotes .search_filter_cont .quotes_btn {
  margin-top: 0;
}

/* perform inspection model section end here */

span.account_edit_icn {
  text-align: center;
  display: inline-block;
  margin-right: 10px !important;
}

.search_filter_cont .quotes_btn_wrap .new_qut_btn {
  margin-right: 10px !important;
}

.search_filter_cont .quotes_btn_wrap .new_qut_btn:last-child {
  margin-right: 0 !important;
}

.quotes_btn_wrap a.new_qut_btn {
  float: inherit !important;
}

/*.appcontent_inner .quotes_btn_wrap {
    margin-top: 15px;
}*/

/* Quill Editor */

.user_newQuote .inspection_content_sec .ql-editor h1 {
  font-size: 24px;
  margin: 10px 0 30px 0;
  line-height: 34px;
  text-align: start;
  color: #3e3433;

}

.user_newQuote .inspection_content_sec .ql-editor h2 {
  font-size: 1.5em;
  margin: 10px 0 30px 0;
  line-height: 34px;
  text-align: start;
  color: #3e3433;
}

.search_filter_cont.quotes_head .app-search {
  max-width: 100%;
  padding: 0;
  margin-bottom: 15px;
}

.appcontent_inner.quotes_head .quotes_btn_wrap {
  text-align: right;
}

.appcontent_inner.quotes_head .quotes_btn_wrap {
  margin-top: 0;
}

.search_filter_cont.quotes_head .quotes_btn {
  margin-top: 0;
}

.quotes_head .quotes_action_btn {
  text-align: left;
}

.app-sidebar {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.app-sidebar .app-menu {
  margin-top: 0 !important;
  padding-top: 16px;
}

.logout {
  padding-bottom: 10px;
}

.app-sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
}

.app-sidebar::-webkit-scrollbar {
  width: 4px !important;
  background-color: rgba(255, 255, 255, 0.1);
}

.app-sidebar::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #000000;
}

.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(21, 27, 38, 1) !important;
}

.swal2-content {
  color: rgb(171, 56, 94);
}

/* sideBar */
.app-sidebar {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.app-sidebar .app-menu {
  margin-top: 0 !important;
  padding-top: 16px;
}

.app-sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
}

.app-sidebar::-webkit-scrollbar {
  width: 4px !important;
  background-color: rgba(255, 255, 255, 0.1);
}

.app-sidebar::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #000000;
}

.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(21, 27, 38, 1) !important;
}

.menu_icn {
  border: 1px solid #566975;
  display: block;
  border-radius: 0.25rem;
  padding: 0.25rem 0.45rem;
  height: 35px;
  text-align: center;
  width: 38px;
  margin: 15px 15px;
  line-height: 22px;
}

.contact_info .menu_icn {
  margin: 4px 0 0 0;
}

.associateAccount .modal-body {
  padding: 2rem 1rem !important;
  max-height: 450px;
  overflow-y: auto;
  height: 100%;
}


.time-field {
  text-align: center;
  border: solid 1px #eee;
  border-radius: 5px;
  color: #5D5D5D;
}

.time-field input {
  border: none;
  border-radius: 5px;
  width: 100%;
  color: #495057;
  text-align: center;
  padding: 5px;
}

.delete-block-area {
  margin-top: 25% !important;
}

.btn-user,
.btn-area {
  transform: none !important;
}

.custom-btn-group .btn-user {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.custom-btn-group .btn-area {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0;
}

.custom-btn-group .btn {
  color: rgb(43, 48, 52);
  border: solid 1px rgb(43, 48, 52);
}

.custom-btn-group .btn.active.show {
  background-color: #850037 !important;
  color: #fff !important;
  border-color: #850037;
}

.front-line-task-block {
  padding: 10px;
  text-align: center;
  background: #eee;
  font-weight: 500;
}

.min-width-btn {
  min-width: 135px;
}

.pointer-none {
  pointer-events: none !important;
}

.new_search {
  display: none;
}

.timeslot-btn {
  background: white;
  border: solid 1px #850037 !important;
  padding: 12px !important;
}


/** 08-06-2020 **/
/** signature screen css **/
.user_newQuote.add_account_modal.signature_modal .inspection_content_sec canvas.quote_form {
  border-bottom: 2px solid #dee2e6;
  padding-bottom: 0;
}

.edit_profile_modal.signature_modal .modal-body {
  padding: 0;
}

.user_newQuote.signature_modal .inspection_content_sec .btn_block {
  margin-top: 25px;
}

.addnew_account_popup.signature_modal .modal-header {
  padding: 15px 15px 15px;
}

/* min-width media */
@media only screen and (min-width: 1367px) {
  .map_part .map_inner {
    width: 400px;
    height: 100%;
    overflow: hidden;
    display: inline-block;
    margin: 0;
    min-height: 200px;
    max-height: 200px;
  }
}

@media (min-width: 992px) {
  .img_gallery_modal .modal-lg {
    max-width: 1100px !important;
  }
}

@media(min-width: 768px) {
  .app.sidenav-toggled .cst_footer {
    width: calc(100% - 50px);
    margin-left: 50px;
  }

  .sidebar-mini.sidenav-toggled .treeview-menu {
    background-color: #151b26 !important;
    margin-top: -6px !important;
  }

  .sidebar-mini.sidenav-toggled .treeview-menu {
    min-width: 200px !important;
  }

  .sidebar-mini.sidenav-toggled .app-menu__label {
    min-width: 200px !important;
  }
}

/** All media query **/
@media(max-width: 1600px) {
  .user_profile_details .user_profile_details_sec .assign_location .assigned_to_div span {
    min-height: 30px;
  }

  .user_profile_details .user_profile_details_sec .assign_location .user_location_box h6 {
    min-height: 30px;
  }
}

@media(max-width: 1536px) {
  .tickets_user_profile_body .border_user_profile::after {
    /* height: 88vh !important; */
  }

  .user_profile_details .user_profile_details_sec .assign_location {
    margin-top: 20px !important;
  }

  .user_profile_details .user_profile_details_sec .priority_id .priority_box span,
  .user_profile_details .user_profile_details_sec .priority_id .user_id_box span {
    font-size: 16px;
  }

  .user_profile_details .user_profile_details_sec .priority_id .priority_box span,
  .user_profile_details .user_profile_details_sec .priority_id .user_id_box span {
    font-size: 16px !important;
  }

  .ptc_cards {
    width: 50% !important;
  }
}

/*End of side bar toggole navigation */
@media (max-width: 1406px) and (min-width: 1200px) {
  .quotes_table_content .cst_btn {
    margin-bottom: 0 !important;
  }
}

@media(max-width: 1440px) {
  #wrap_register .inner_form {
    margin: 40px auto 40px;
  }

  .admin_dashboard .performance_chart .chart_title1 {
    min-height: 25px !important;
  }

  /*15/11/19*/
  .img_gallery_modal .modal-lg {
    max-width: 1000px !important;
  }

  .img_gallery_modal .image-gallery-image img {
    height: 550px !important;
  }

  .admin_dashboard .performance_chart .chart_title1 {
    min-height: 40px !important;
  }
}

@media(max-width: 1854px) {
  .admin_dashboard .chart-content .element {
    height: 155px !important;
  }
}

/*
@media(min-width: 1439px) and (max-width: 1560px) {

    .admin_dashboard .chart-content {
        height: 160px !important;
    }
} */

/* signin CSS Here page */
@media(max-width: 1366px) {
  .admin_dashboard .circle img {
    width: 32px !important;
  }

  .admin_dashboard .circle {
    width: 60px !important;
    height: 60px !important;
  }

  .admin_dashboard .performance_chart .chart_title1 {
    min-height: 20px !important;
  }

  .chart_section .admin_dashboard .tile_block h2 {
    font-size: 20px;
  }

  .admin_dashboard .performance_chart .chart_title1 {
    margin-bottom: 10px !important;
  }

  .tickets_user_profile_body .user_status .cst_btn {
    margin-right: 5px !important;
  }

  .resolveModal .MuiPaper-root {
    width: 70% !important;
  }
}

@media all and (max-height: 768px) and (max-width: 1366px) {
  .limiter .container-login100 .login100-more {
    padding: 140px 40px 40px;
  }

  .limiter .container-login100 .login100-more .foot-note {
    margin: 15px auto 0;
  }

  .limiter .container-login100 .login100-more .foot-note h2 {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .limiter .container-login100 .login100-more .foot-note h5 {
    font-size: 18px;
  }

  .limiter .container-login100 .login100-more .foot-note .mt_25 {
    margin-top: 0;
  }

  .avg_performance_img img {
    height: 219px;
  }

  .inspector_report .tile_block h4 {
    font-size: 14px;
  }

  .inspector_report .card-body {
    padding: .75rem;
  }

  .cst_grid {
    padding-right: 5px;
    padding-left: 5px;
  }

  .scripps_content .performance_box {
    padding: 47px 0 !important;
  }

  .scripps_content .overall_performance_img img {
    height: 222px !important;
  }

  /*approve media*/
  .work_order_block .md_title {
    font-size: 30px;
  }

  .work_order_block .w_order_media .media_title {
    font-size: 26px;
  }
}

@media all and (max-height: 654px) and (max-width: 1366px) {
  .login-logo img {
    width: 160px;
  }

  .limiter .inner_form {
    margin: 15px auto 0;
  }

  .limiter .container-login100 .login100-more {
    padding: 120px 40px 40px;
  }

  .limiter .container-login100 .login100-form {
    padding: 32px 0 0;
  }

  .limiter .inner_form h1 {
    font-size: 32px;
    margin: 0 0 20px;
  }

  .checkbox_block {
    margin-bottom: 15px;
  }

  .limiter .inner_form .btn_lg {
    margin-bottom: 15px;
  }

  .wrap-login100 .mt_30 {
    margin-top: 15px !important;
  }

  .limiter .container-login100 .login100-more .img-login {
    width: 65%;
  }
}

@media all and (max-height: 694px) and (max-width: 1366px) {
  .login-logo img {
    width: 160px;
  }

  .limiter .inner_form {
    margin: 15px auto 0;
  }

  .limiter .container-login100 .login100-more {
    padding: 120px 40px 40px;
  }

  .limiter .container-login100 .login100-form {
    padding: 32px 0 0;
  }

  .limiter .inner_form h1 {
    font-size: 32px;
    margin: 0 0 20px;
  }

  .checkbox_block {
    margin-bottom: 15px;
  }

  .limiter .inner_form .btn_lg {
    margin-bottom: 15px;
  }

  .wrap-login100 .mt_30 {
    margin-top: 15px !important;
  }

  .limiter .container-login100 .login100-more .img-login {
    width: 65%;
  }
}

@media(max-width: 1280px) {
  .search_filter_cont .cst_btn.quotes_btn {
    min-width: 70px !important;
  }

  .img_gallery_modal .image-gallery-image img {
    height: 550px;
  }
}

@media(max-width: 1250px) {
  .more_filter_btn.btn-link {
    margin-right: 0 !important;
  }
}

@media(max-width: 1199px) {
  .empty-graph {
    min-height: auto;
    padding: 0px 0px 25px;
  }

  .quotes_table_content .cst_btn {
    margin-bottom: 0px !important;
  }

  .search_filter_cont .btn {
    margin-top: 0;
    min-width: 80px !important;
  }

  .quotes_btn_wrap {
    margin-top: 0 !important;
  }

  .search_filter_cont .fil_search_input {
    margin-bottom: 0;
  }

  .search_filter_cont .custom-select {
    margin-bottom: 15px;
  }

  /*.search_filter_cont .app-search__button {
        top: -6px !important;
    }*/
  .approve_Quotes_sec .work_order_block .md_title {
    font-size: 24px;
  }

  .approve_Quotes_sec .work_order_block .w_order_media .media_title {
    font-size: 20px;
  }

  .approve_Quotes_sec .work_order_block {
    padding: 20px 20px 20px;
  }

  .appcontent_inner .quotes_btn_wrap {
    text-align: right !important;
  }

  .app.sidebar-mini.rtl.dashboard_page_body.sidenav-toggled {
    position: relative;
  }

  .user_profile_details {
    height: 100% !important;
  }

  .user_profile_details .user_profile_details_sec {
    margin-top: 10px !important;
  }

  .user_profile_details .user_profile_details_sec .assign_location .user_location_box h6 {
    min-height: auto !important;
    margin-bottom: 0;
  }

  .user_profile_details .user_profile_details_sec .assign_location .assigned_to_div span {
    min-height: auto !important;
  }

  .user_profile_details .user_profile_details_sec .user_status {
    padding: 20px 0 10px !important;
  }

  .tickets_user_profile_body .user_status .cst_btn {
    margin-bottom: 0 !important;
  }

  .user_profile_details .user_profile_details_sec .assign_location .user_location_box h6 {
    margin-bottom: 0.5rem !important;
  }

  .search_filter_cont .quotes_btn {
    margin-top: 0 !important;
  }

  .search_filter_cont .btn.account_btn {
    min-width: auto !important;
  }

  .ptc_cards {
    width: 65% !important;
  }
}

/* signin CSS Here page */
@media screen and (max-width: 1100px) {
  .wraper_content .filter_block {
    margin-right: 10px !important;
  }

  .approve_Quotes_sec .header_block .logo_img {
    width: 150px;
  }
}

@media(max-width: 1075px) {
  .search_filter_cont .btn.cst_btn.btn_danger.mr-2 {
    margin-bottom: 0 !important;
    margin-right: 10px !important;
  }

  .MuiDialogActions-spacing>*+* {
    margin-top: -10px !important;
  }

  .quotes_table_content .cst_btn {
    margin-right: 8px !important;
    margin-top: 0px !important;

  }

  .edit_rating .btn_danger {
    margin-right: 10px !important;
  }

  .setting_form_con .btn_danger {
    margin-right: 10px !important;
  }

  .comment_buttons .btn_danger {
    margin-right: 10px !important;
  }

  .timeslot-btn {
    background: white;
    border: solid 1px #850037 !important;
    padding: 12px !important;
  }

  .qr-app-scroll {
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 100px !important;
  }

  .new_search {
    display: block;
  }

  .new_search-desktop {
    display: none;
  }

  .previous-history-block .title_block {
    width: 130px !important;
  }

  .previous-history-block .mob_border_view {
    width: calc(100% - 130px) !important;
  }

  /* 29-06-2020*/
  ul.custom-btn-group.nav.nav-pills {
    margin-left: 10px;
  }
}

@media(max-width: 1024px) {
  .account_sec .user_react_table .mr_20 {
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
  }

  .resolveModal .MuiPaper-root {
    width: 80% !important;
  }

  .rm_attachmentIMG {
    width: 70px !important;
  }
}

@media(max-width: 991px) {
  .admin_dashboard .app_inner_title {
    padding: 14px 0 15px !important;
  }

  .react_times_button.time_picker_preview .preview_container.without_icon {
    font-size: 14px;
    padding: 10px 10px;
  }

  .search_filter_cont .app-search {
    max-width: 100% !important;
    margin-bottom: 0;
  }

  /* .search_filter_cont .quotes_btn_wrap {
         justify-content: left;
         display: flex;
     }*/
  .search_filter_cont a.btn.btn-secondary.cst_btn.new_qut_btn {
    margin: 0;
  }

  .text-right.accounts_btn_wra.theme_col_px {
    text-align: left !important;
  }

  .setting_form_con .rating_info .icn_block {
    padding: .8rem .8rem .8rem !important;
  }

  /*15/11/19*/
  .img_gallery_modal .modal-lg {
    max-width: auto !important;
  }

  .img_gallery_modal .image-gallery-image img {
    height: auto !important;
    object-fit: inherit !important;
  }

  .img_gallery_modal .modal-dialog {
    margin: 1.75rem 25px;
  }

  .search_filter_cont .btn {
    min-width: auto !important;
  }

  .search_filter_cont .app-search {
    margin-bottom: 15px;
  }

  .accounts_btn_wrap {
    margin-bottom: 15px;
  }

  .user_profile_details .user_profile_details_sec .priority_id .user_id_box:after {
    left: -65px !important;
  }

  .search_filter_cont .cst_btn.quotes_btn {
    min-width: auto !important;
  }

  .contact_info .menu_icn {
    margin: 6px 0 6px 0 !important;
    width: 30px;
    height: 30px;
    line-height: 17px;
  }

  .edit_ins_content .rating_card_body {
    padding: 3rem 1.55rem 1rem !important;
  }

  .setting_form_con .rating_info .icn_block .cst_icon {
    font-size: 16px;
  }

  /* 30-06-2020 */
  .report_content .sub_head .cst_btn {
    min-width: 120px;
  }

  .wraper_content .report_content .calender_block .filter_cal_box {
    margin-top: 0;
  }

  .report_content .admin_dashboard .app_inner_title {
    padding: 0 0 25px !important;
  }

  .edit_ins_content .edit_info_form .rating_card_body {
    padding: 0.75rem 1.75rem 1rem !important;
  }
}

@media only screen and (max-width: 990px) {
  .inspection_content_block .left_list {
    display: inline-block;
    width: 100%;
    min-width: 130px;
    max-width: 130px;
    text-align: right;
    margin-right: 13px;
  }

  .inspection_content_block .right_list {
    display: block;
    width: calc(100% - 130px);
    margin-left: auto;
  }

  .map_part {
    width: 100%;
    display: block;
    padding: 20px 20px 15px 20px;
  }

  .inspection_content_block:first-child {
    margin-top: 15px;
  }

  .map_part .map_inner {
    width: 100%;
  }

  .ptc_cards {
    width: 75% !important;
  }

  .ptc_buttons {
    width: 29% !important;
    margin-right: 11px !important;
  }
}

@media(max-width: 825px) {
  .admin_dashboard .circle {
    width: 50px !important;
    height: 50px !important;
  }

  .admin_dashboard .circle img {
    width: 30px !important;
  }

  .chart_section .admin_dashboard .tile_block h2 {
    font-size: 20px;
  }

  .ptc_buttons {
    width: 32% !important;
    margin-right: 11px !important;
  }
}

@media(max-width: 768px) {
  .ptc_cards {
    margin-left: 5px !important;
  }
}

@media(max-width: 767px) {

  /* side bar toggole navigation  23-07-19*/
  .react_times_button.time_picker_preview {
    /*padding: 8px 0 15px;*/
    float: left;
    margin-left: 10px;
  }

  .app-sidebar__toggle {
    margin: 0 5px 0 0 !important;
  }

  .mob_header {
    padding: 8px 0 !important;
  }

  .mob_header .app_title {
    margin-top: 10px;
  }

  .mob_header .app-nav {
    margin-top: 0px;
  }

  .app-sidebar {
    top: 15px !important;
  }

  .app-menu {
    margin-top: 0px !important;
  }

  /* side bar toggole navigation 23-07-19*/
  .edit_inspection .tickets_user_profile_body .user_activity_conent .attachment_sec .attachments_files .attachments__file_box {
    width: 18%;
  }

  .user_newQuote.wraper_content .inspection_content_sec h1 {
    text-align: center !important;
  }

  .app-content.rating_con {
    margin-top: 51px !important;
  }

  .user_profile_details .user_profile_details_sec {
    margin-top: 30px !important;
  }

  .export_csv_block .input-modifier {
    margin-bottom: 15px;
  }

  .user_profile_details .user_profile_details_sec .priority_id .priority_box span,
  .user_profile_details .user_profile_details_sec .priority_id .user_id_box span {
    font-size: 14px !important;
  }

  .quotes_action_btn {
    text-align: center;
  }

  .quotes_action_btn {
    text-align: center;
  }

  .quotes_head .quotes_btn_wrap .new_qut_btn {
    margin-top: 10px;
  }

  .history_block .activity-feed .feed-item .list_content {
    max-width: 520px;
    white-space: normal !important;
    display: block;
  }

  /* 30-06-2020 */
  .report_content .sub_head {
    padding: 10px 0 20px !important;
  }

  .report_content .admin_dashboard .app_inner_title {
    padding: 0 0 15px !important;
  }

  .dashboard_page_body .report_content .appcontent_Inner {
    padding: 0 !important;
  }

  .report_content .react_times_button.time_picker_preview {
    padding: 10px 0;
    margin-left: 0;
  }

  .report_content .sub_head .cst_btn {
    min-width: 110px !important;
  }

  .accounts_user_btn button.btn.btn-secondary.float-right.cst_btn {
    float: inherit !important;
    margin-right: 4px;
  }

  /* 13-07-2020 */
  .chart_section .react_times_button.time_picker_preview {
    margin-left: 0;
  }

  .resolveModal .MuiPaper-root {
    width: 80% !important;
  }

  .rm_wrapper {
    margin-top: 5% !important;
  }
}

@media(max-width: 599px) {
  .search_filter_cont .btn {
    margin-top: 0;
  }
}

@media(max-width: 575px) {
  .quotes_btn {
    margin-top: 0 !important;
  }

  .empty-graph div {
    font-size: 15px
  }

  .ReactTable .-pagination .-btn {
    min-width: 40px !important;
    padding: 7px 7px !important;
  }

  .ReactTable .-pagination .-pageSizeOptions {
    margin: 0px !important;
  }

  .ReactTable .-pagination input,
  .ReactTable .-pagination select {
    padding: 7px 0px !important;
  }

  .ReactTable .-pagination .-pageInfo {
    margin: 0px !important;
  }

  .ReactTable .-pagination {
    font-size: 14px;
  }

  .account_btn {
    margin-top: 0px;
  }

  .ReactTable .-pagination select {
    margin: 0 0px 0 8px;
    padding: 7px 7px !important;
    margin-left: 10px !important;
    width: 95px;
  }

  .search_filter_cont .quotes_btn_wrap a {
    float: right !important;
  }

  .-pagination .-totalPages {
    margin-left: 8px;
  }

  .inspection_content_sec.appcontent_Inner {
    padding: 20px 0 0 0 !important;
  }

  .user_newQuote .inspection_content_sec .addQuote_form {
    padding: 22px 20px 30px 20px !important;
  }

  .char_empty_cart {
    left: 0;
  }

  .search_filter_cont .quotes_btn_wrap {
    justify-content: right;
    display: inline-block;
    text-align: right !important;
  }

  .dashboard_page_body .user_newQuote.wraper_content .inspection_content_sec .quote_form {
    margin-top: 20px;
  }

  .user_newQuote.wraper_content .inspection_content_sec h1 {
    margin-bottom: 15px !important;
    font-size: 20px !important;
  }

  .user_newQuote .inspection_content_sec .addQuote_form .btn_block {
    margin-top: 30px;
  }

  .edit_inspection .tickets_user_profile_body .user_activity_conent .attachment_sec .attachments_files .attachments__file_box {
    width: 22%;
  }

  .add_New_Accont h4.section_title {
    font-size: 22px;
    margin: 0 0 10px 0;
  }

  .approve_Quotes_sec .work_order_block .md_title {
    font-size: 20px;
    line-height: 32px;
  }

  .approve_Quotes_sec .work_order_block .w_order_media .media_title {
    font-size: 18px;
  }

  .approve_Quotes_sec .work_order_block .circle_img img {
    width: 45px !important;
    height: 45px !important;
  }

  .setting_form_con .rating_info .icn_block {
    padding-top: 0.8rem !important;
  }

  .edit_ins_content .card_footer {
    margin-top: 0 !important;
  }

  .edit_inspection .rating_form_card .card_footer {
    padding: .60rem 0 1.25rem !important;
  }

  .approve_Quotes_sec .header_block .logo_img {
    width: 130px;
  }

  .app.sidebar-mini.rtl.dashboard_page_body.sidenav-toggled::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 68px;
    left: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
  }

  .toggle-sideBar {
    overflow: hidden;
  }

  .tickets_user_profile_body .user_activity_conent .tickets_users_comments .sent_comments .user_profile_icn img {
    height: auto !important;
  }

  .tickets_users_comments .sent_comments .media {
    display: block !important;
  }

  .user_profile_details .user_profile_details_sec .priority_id .user_id_box:after {
    left: -50px !important;
  }

  .app-content.ticket_user {
    padding: 15px 0;
  }

  .quotes_head .quotes_btn_wrap {
    text-align: center !important;
  }

  .quotes_head .quotes_action_btn {
    text-align: center !important;
    margin-bottom: 15px;
  }

  .contact_info .menu_icn {
    margin: 0 0 10px 0 !important;
  }

  .menu_icn {
    margin: 35px 10px 0 !important;
  }

  .edit_inspection .inner_card .ins_form_btn .cst_btn {
    word-break: break-word;
    white-space: inherit;
    font-size: 12px !important;
    padding: 0.55rem 0.45rem;
    margin-left: 6px;
  }

  .inspection_tab_content .quotes_btn_wrap a {
    float: inherit !important;
  }

  .inspection_tab_content.search_filter_cont .quotes_btn_wrap {
    text-align: center !important;
    margin-top: 10px !important;
  }

  .more_filter_block {
    text-align: center;
  }

  .inspection_tab_content.search_filter_cont .more_filter_block .btn {
    min-width: 80px !important;
  }

  .react-datepicker__input-container {
    margin-bottom: 10px;
  }

  .more_filter_content .card-body {
    padding: 10px 0 0 !important;
  }

  .css-2b097c-container {
    margin-bottom: 10px;
  }

  .card-body.select_ft_cbody {
    padding: 0 !important;
  }

  .inspection_wraper .search_filter_cont .quotes_btn_wrap .btn-secondary {
    min-width: 80px !important;
  }

  .accounts_btn_wra.theme_col_px {
    margin-top: 10px;
  }

  /* 29-06-2020 */
  .search_filter_cont .rset_btn {
    text-align: center !important;
    margin-bottom: 10px;
  }

  .accounts_user_btn.theme_col_px {
    text-align: center !important;
  }

  /* 01-07-2020 */
  .multi_Select.css-2b097c-container {
    margin-bottom: 0;
  }

  .select_filter_block .form_title {
    margin-right: 2px !important;
    min-width: 75px !important;
  }

  .report_content_block .select_filter_block .activity-feed .cst_input_group {
    width: 122px !important;
  }

  .resolveModal .MuiPaper-root {
    width: 90% !important;
  }

  .rm_imgAllign {
    float: right !important;
    width: 105% !important;
    justify-content: flex-start !important;
  }

  .ptc_cover {
    flex-direction: column !important;
  }

  .ptc_cards {
    width: 306% !important;
    flex-direction: column !important;
  }

  .ptc_buttons {
    border-right: 4px solid #850037 !important;
  }
}

@media(max-width: 480px) {
  .inspection_wraper .search_filter_cont .quotes_btn_wrap .btn-secondary {
    font-size: 14px;
  }

  .chart_section .admin_dashboard .tile_block h2 {
    font-size: 16px;
    line-height: 24px;
  }

  .chart-content .col-sm-6 {
    padding: 0 5px !important;
  }

  .admin_dashboard .circle img {
    width: 25px !important;
  }

  .admin_dashboard .circle {
    width: 45px !important;
    height: 45px !important;
  }

  .tickets_user_profile_body .user_activity_conent .tickets_users_comments .sent_comments .tickets_user_bottomSec .user_comment_area {
    padding: 10px !important;
  }

  .dashboard_page_body .appcontent_Inner {
    padding: 20px !important;
  }

  .tickets_user_profile_body .comment_buttons .cst_btn {
    min-width: 100px !important;
  }

  .user_profile_details .user_profile_details_sec .user_profile_title {
    margin-left: 0 !important;
  }

  .user_profile_details .user_profile_details_sec .user_profile_picture {
    float: inherit;
  }

  .file-upload-wrapper {
    cursor: pointer !important;
    height: 10px !important;
  }

  .add_account_modal .cst_btn {
    min-width: 100px !important;
  }

  /*08-06-2020*/
  .user_newQuote .inspection_content_sec .btn_block {
    margin-top: 30px;
  }

  .user_newQuote.signature_modal .inspection_content_sec .btn_block {
    padding: 0 15px;
  }

  /* 29-08-2020 */
  .report_content .sub_head {
    margin-top: 10px !important;
  }
}

@media(max-width: 470px) {
  .edit_inspection.settings .card_footer .btn_block .cst_btn {
    margin-bottom: 10px;
  }
}

@media screen and (max-width:446px) {
  .user_newQuote .inspection_content_sec .btn_block .btn {
    margin-bottom: 10px !important;
  }

  .edit_inspection .card_footer .btn_block .cst_btn {
    margin-bottom: 10px !important;
  }
}

@media(max-width: 420px) {
  .admin_dashboard br {
    display: none;
  }

  .react_times_button.time_picker_preview {
    margin-right: 0;
  }

  .tickets_user_profile_body .comment_buttons .cst_btn {
    min-width: auto;
    margin-bottom: 10px !important;
  }

  .file-upload-wrapper:after {
    padding: 10px 0 !important;
    width: 90% !important;
  }

  .file-upload-wrapper:before {
    top: -11px !important;
  }

  .user_profile_details .user_profile_details_sec .user_profile_picture {
    float: inherit !important;
  }
}

@media(max-width: 414px) {
  .char_empty_cart {
    left: 0;
  }

  .admin_dashboard .performance_chart .chart_title1 {
    min-height: 45px !important;
  }

  .admin_dashboard .card_f_txt h6 {
    font-size: 13px;
    min-height: 40px;
    line-height: 20px;
  }

  .chart_section .admin_dashboard .tile_block h2 {
    font-size: 14px;
  }

  .admin_dashboard .circle {
    width: 45px;
    height: 45px;
  }

  .admin_dashboard .circle img {
    width: 26px;
  }

  .admin_dashboard .performance_chart .chart_title1 {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .user_newQuote.wraper_content .inspection_content_sec .quote_form {
    margin-top: 15px;
  }

  .add_New_Accont h4.section_title {
    font-size: 20px;
  }

  .edit_inspection .inner_card ul.list_con li {
    white-space: pre-wrap;
    line-height: 20px;
  }
}

@media(max-width: 400px) {
  .edit_inspection .tickets_user_profile_body .user_activity_conent .attachment_sec .attachments_files .attachments__file_box {
    width: 29%;
  }

  /* 29-06-2020 */
  .report_content .export_btn {
    float: inherit;
    margin-top: 15px;
  }

  .report_content .sub_head {
    padding: 15px 0 5px 0 !important;
  }

  .report_content .admin_dashboard .app_inner_title {
    padding: 0 0 15px !important;
  }

  .xl_logo {
    width: 100px !important;
  }

  .app-sidebar__toggle {
    padding: 0 13px 0 9px !important;
    line-height: 35px;
    width: 35px;
    height: 35px;
  }

  .sidebar-mini .app-sidebar__toggle .toogle_left .fa-bars {
    line-height: 37px;
  }
}

@media(max-width: 375px) {
  .char_empty_cart {
    left: 0;
  }

  .admin_dashboard .circle {
    width: 35px !important;
    height: 35px !important;
  }

  .admin_dashboard .circle img {
    width: 18px !important;
  }

  .admin_dashboard .performance_chart .chart_title1 {
    min-height: 45px !important;
  }

  .react_times_button.time_picker_preview {
    margin-left: 0px;
  }

  .file-upload-wrapper:after {
    width: 85% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dashboard_page_body .appcontent_Inner.inspection_tab_content {
    padding: 15px !important;
  }

  .search_filter_cont {
    padding: 20px 15px 20px 15px !important;
  }

  .search_filter_cont {
    margin-top: 19px !important;
  }

  span.items.mr-3 {
    margin-right: 5px !important;
  }

  .items-collection label.select_style_links {
    margin: 6px 0 !important;
  }

  .create_quote_text .after_popup_text {
    font-size: 14px;
  }

  .search_filter_cont .cst_btn.quotes_btn {
    min-width: auto !important;
  }

  .inspection_tab_content.search_filter_cont .btn {
    min-width: auto !important;
  }

  /* 29-06-2020 */
  .ReactTable .-pagination .-btn {
    padding: 8px 3px !important;
    font-size: 11px !important;
  }

  .ReactTable .-pagination select {
    width: 75px !important;
    padding: 7px 5px !important;
    font-size: 11px !important;
  }

  .ReactTable .-pagination input {
    width: 10px !important;
  }

  .rm_imgAllign {
    width: 105% !important;
    justify-content: center !important;
  }

  .ptc_cards {
    width: 300% !important;
  }
}

@media(max-width:363px) {

  .ReactTable .-pagination .-btn {
    font-size: 13px !important;
  }

  .ReactTable .-pagination input,
  .ReactTable .-pagination select {
    font-size: 13px !important;
  }

  .ReactTable .-pagination select {
    padding: 7px 7px !important;
    margin: 0 4px !important;
    background-position: 90% 15px !important;
    background-size: 10px !important;
    width: 90px;
  }

  .ReactTable .-pagination input {
    width: 22px !important;
  }

  .chart_section .admin_dashboard .tile_block h2 {
    font-size: 14px;
  }

  .approve_Quotes_sec .inner_content {
    padding: 10px !important;
  }

  .approve_Quotes_sec .work_order_block {
    padding: 20px 10px 20px !important;
  }

  .work_order_block .w_order_media .btn_block .cst_btn {
    min-width: 80px !important;
  }

  /* 07-07-2020 */
  .mob_header .app_title {
    display: none;
  }
}

@media(max-width: 347px) {

  /* .ReactTable .-pagination input {
        padding:
    } */
  .ReactTable .-pagination select {
    width: 80px !important;
    padding: 6px 7px !important;
    font-size: 12px !important;
    background-position: 90% 14px !important;
    background-size: 9px !important;
  }

  .ReactTable .-center {
    font-size: 12px !important;
  }

  .ReactTable .-pagination .-btn {
    padding: 6px 6px !important;
    font-size: 12px !important;
  }

  .admin_dashboard .calc_content li {
    padding: 11px 11px !important;
    font-size: 13px;
  }

  .wraper_content .filter_block .list_filter .list_link {
    font-size: 13px;
  }

  .MuiDialogTitle-root {
    padding: 13px 40px 6px !important;
  }

  .MuiTypography-root.MuiTypography-h6 h3 {
    font-size: 20px;
  }

  .profile_changes_pic .cst_nav_tab {
    padding: 7px 18px 0 18px !important;
  }
}

@media(max-width: 340px) {
  .admin_dashboard .app_inner_title {
    padding: 14px 0 0px !important;
  }

  .char_empty_cart {
    left: 0;
  }
}

@media screen and (max-width:331px) {
  .user_newQuote .inspection_content_sec .btn_block .btn {
    min-width: 100% !important;
  }

  .edit_inspection .card_footer .btn_block .cst_btn {
    min-width: 100% !important;
  }

  .user_newQuote .inspection_content_sec .btn_block .btn {
    max-width: 100%;
  }

  .inspection_tab_content.search_filter_cont .btn {
    min-width: auto !important;
  }
}

.btn:hover,
.btn:focus {
  text-decoration: none;
  transform: inherit !important;
  box-shadow: none !important;
}

.multi_Select {
  width: 300px;
  height: 36px;
  margin-right: 10px;
}

.select_filter_block .cst_input_group .form-control {
  width: 100%;
}

/* 29-06-2020 */
/* view account screen changes*/
.search_filter_cont .rset_btn {
  white-space: nowrap;
  text-align: right;
}

/* 30-06-2020 */
.report_content_block .cst_input_group .input-group-append {
  position: absolute;
  right: 0;
  height: 36px;
  top: 0;
}

.dashboard_page_body .report_content .appcontent_Inner {
  padding: 0;
}

.wraper_content .report_content .filter_block .list_filter .list_link {
  width: 61px;
  height: 41px;
}

/* 01-7-2020 */
.weight_modal .close:hover,
.weight_modal .close:focus,
.weight_modal .close:active {
  outline: none;
}

/* 07-07-2020 */
.graph_tabs .chart-box-botL .tile_ft {
  padding: 0;
  margin-bottom: 15px;
  text-align: right;
}

.ReactTable .qr-tr-table {
  align-items: baseline !important;
}

.grid_card .cst_para {
  color: #251b26 !important;
}

.row_align .radius_checkbox {
  display: inline-flex !important;
  margin-right: 1rem !important;
}

.borderNone {
  border-radius: 0% !important;
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #518653;
}

.floatLeft {
  float: left;
}

.dispContent {
  display: contents;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

#datatable {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #EBEBEB;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

#datatable caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

#datatable th {
  font-weight: 600;
  padding: 0.5em;
}

#datatable td,
#datatable th,
#datatable caption {
  padding: 0.5em;
}

#datatable thead tr,
#datatable tr:nth-child(even) {
  background: #f8f8f8;
}

#datatable tr:hover {
  background: #f1f7ff;
}

.user_quota_form {
  max-width: 100% !important;
}

.availability_form {
  max-width: 67% !important;
}

.overrideAvailability_form {
  max-width: 45% !important;
}

/* .gridStyle {
  padding-top: 10px;
  height: 40px;
  padding-left: 10px;
} */
.f12 {
  font-size: 12.5px;
}

.performance_modal.add_account.associateUser .form-group.checkbox_cst_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}

.checkbox_cst_grid .checkbox_block {
  border: 1px solid #c7cdd1;
  border-radius: 4px;
  height: max-content;
  padding: 10px;
}

.radius_checkbox.checkbox_block {
  margin-bottom: 10px;
}

.gridStyle {
  padding: 15px;
  width: 100%;
}

.gridInlineBlc {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
}

.gridStyle label {
  margin-bottom: 0;
  font-size: 13px;
}

.gridStyle .icnBlc {
  min-width: 55px;
  text-align: right;
}

.icnBlc span.account_edit_icn:last-child {
  margin-right: 0 !important;
}

/* switch button*/
.theme_switch_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #2b3034;
  white-space: nowrap;
  transition: all .3s ease-in-out;
}

.theme_switch_btn>label {
  position: relative;
  display: inline-block;
  width: auto;
  height: 20px;
  line-height: 20px;
  margin-bottom: 0;
}

.theme_switch_btn>label>input {
  opacity: 0;
  width: 0;
  margin-right: 38px;
}

.theme_switch_btn>label>input:checked+*:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/*.theme_switch_btn > label > input:focus + * {*/
/*box-shadow: 0 0 0 0.2rem rgba(168,179,188,.25);*/
/*}*/
.theme_switch_btn>label>input+* {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a8b3bc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 36px;
  border-radius: 24px;
}

.theme_switch_btn>label>input+*::before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.theme_switch_btn>label>input:checked+* {
  background-color: #850037;
}

.switch_title {
  margin-right: 6px;
  display: inline-block;
}

.ext_title {
  display: inline-block;
  margin-left: 3px;
}

.app-header .user_menu_dropdown.user_profile .user_circle {
  border: 1px solid #dbdbdb;
}

@media screen and (max-width:991px) {
  .performance_modal.add_account.associateUser .form-group.checkbox_cst_grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
  }
}

@media(min-width:768px) {
  .comingSoon {
    position: absolute;
    top: 50%;
    left: 54%;
  }
}

@media(max-width:768px) {
  .comingSoon {
    position: absolute;
    top: 50%;
    left: 40%;
  }

  .inspection_content_sec .positionRegions .ticket_form .addQuote_form {
    padding: 0;
  }
}

.areaNameBtn {
  min-width: 20%;
  max-width: 50%;
}

.closeIcon {
  padding: 5px 10px 0px 0px !important;
}

.sureTxt {
  padding: 0px 60px 8px !important;
}


/* QQV design css */
.qqa_btn_blc {
  padding: 15px 20px;
  position: fixed;
  top: 67px;
  z-index: 99;
  background: #fff;
  width: calc(100% - 230px);
  text-align: right;
  left: 230px;
}

.card.edit_ins_content {
  margin-top: 70px;
}

.perform_qqv .cst_btn {
  min-width: 105px;
}

.perform_qqv .cst_btn:first-child {
  margin-right: 6px;
}

.perform_qqv .modal_title {
  font-size: 19px;
  line-height: 26px;
  font-weight: 700;
  color: #151b26;
  margin-bottom: 25px;
  text-align: center;
}

.perform_qqv .btn_block {
  text-align: center;
}

.perform_qqv .modal-body {
  padding: 25px 20px;
}



.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

@media (min-width: 575px) {
  .setUnavailable_modal .modal-dialog {
    max-width: 415px;
  }
}

@media (min-width: 1366px) {
  .history_block .activity-feed .feed-item {
    padding-bottom: 25px;
  }

  .history_block .activity-feed .feed-item:last-child {
    padding-bottom: 25px;
  }
}

@media (min-width: 992px) {
  .print_qr_modal .modal-lg {
    max-width: 1200px;
  }

  .web_modal .modal-lg {
    max-width: 1400px;
    margin: 15px auto;
  }
}

.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

/** 14-08-2020 **/
@media (max-width: 1440px) {
  .web_modal {
    padding-right: 0 !important;
  }

  .web_modal .modal-lg {
    margin: 15px auto !important;
  }

  .web_pagination {
    flex-wrap: wrap;
  }

  /* 17-08-2020*/
  .chi_surveys .grid_card .cst_para {
    line-height: 20px;
    font-size: 13px !important;
  }
}

/*** userDetails screen media here ***/
@media (max-width: 1366px) {

  /* line 7082, ../sass/custom/_custom.scss */
  .userDetails .card-body {
    padding: 1.5rem;
  }

  .custom-radio .form-check-input:checked {
    background-color: red;
    border-color: red;
  }
  .custom-radio .form-check-input:focus {
    box-shadow: none;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #2196F3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  } */
    .userDetails .card-body {
      padding: 1.5rem;
    }
  

  /* line 7085, ../sass/custom/_custom.scss */
  .userDetails .profile-img {
    width: 100px;
    height: 100px;
  }

  .report_content .grid_view {
    grid-column-gap: 10px !important;
  }

  .grid_card .cst_para {
    font-size: 12px !important;
    line-height: 18px;
  }

  .conference_head {
    margin-bottom: 12px;
  }

  .client_conference_con .history_block .h3_title {
    margin-bottom: 0;
  }

  .client_conference_con .history_block .activity-feed .feed-item {
    padding-bottom: 13px;
  }

  .client_conference_con {
    margin-bottom: 0;
  }

  .client_conference_con .history_block .activity-feed .feed-item:before {
    height: 34px;
    left: -1px;
  }

  .history_block .activity-feed .feed-item {
    padding-bottom: 17px;
    font-size: 15px;
  }

  .add_content_block {
    padding: 10px 15px;
  }

  .history_block .activity-feed .feed-item:last-child {
    padding-bottom: 0;
  }

  .history_block .activity-feed {
    margin-top: 10px;
  }

  .history_block .activity-feed .feed-item .text_md {
    font-size: 18px;
  }

  .history_block {
    padding: 90px 0 30px;
  }

  .history_block .activity-feed .feed-item:before {
    height: 42px;
  }

  .client_conference_con .history_block .add_content_block {
    padding: 0px 15px;
  }

  .client_conference_con .history_block {
    margin-top: 75px;
  }

  /* 17-08-2020 */
  .chi_scores_graph img {
    width: 100%;
  }

  .history_warp.scan_result_history .btn_block {
    margin-top: 20px;
  }

  .scan_result_history .add_content_block {
    padding: 15px 15px 20px;
  }
}

@media (max-width: 1199px) {

  /* line 7091, ../sass/custom/_custom.scss */
  .userDetails {
    margin-top: 25px;
  }

  /* line 7094, ../sass/custom/_custom.scss */
  .userDetails .profile-info {
    margin-top: 25px;
  }

  .report_content .grid_view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
  }

  .report_content .grid_view .grid_card {
    margin-bottom: 10px;
  }

  .report_content .sub_head .cst_btn {
    margin-bottom: 0;
  }

  /** add area media **/
  .add_info_block .h2_title {
    font-size: 32px;
  }

  /* qr history media*/
  .qr_app_content .theme_sub_header .custom-select {
    margin-right: 10px;
  }

  .print_qr_modal {
    padding: 0 10px ! important;
  }

  /* 03-07-2020 */
  .card.border-0.description_card {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .card.description_card {
    margin-bottom: 0 !important;
  }

  /* 12-08-2020 */
  .chi_report .inline_label {
    width: 100%;
    float: inherit;
    vertical-align: middle;
    transform: inherit;
  }

  .chi_report .tile {
    width: 100%;
  }

  .chi_report .checkbox_block.radius_checkbox {
    min-width: 100%;
  }

  .chi_report .form_input_block {
    margin-right: 0;
  }

  .export_btn .btn_danger {
    margin-bottom: 0;
  }

  /** 14-08-2020 **/
  .web_inn_container {
    margin: 15px auto;
  }

  .web_h3 {
    font-size: 22px;
    line-height: 32px;
    color: #344654;
  }

  .web_logo img {
    width: 200px;
  }

  .web_inn_con {
    margin-top: 30px;
  }

  .web_modal .modal-lg {
    max-width: 100%;
  }

  /* 17-08-2020 */
  .chi_surveys .grid_view {
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
  }

  .view_inspection_btn {
    text-align: left;
  }

  .survey_response .user_profile_details .user_profile_details_sec {
    margin-top: 30px;
  }

  .survey_response .user_profile_details .user_profile_details_sec .user_profile_picture {
    width: 80px;
    height: 80px;
  }

  .survey_response .response_sub_head {
    padding: 35px 20px 0;
  }

  .pg_common_modal {
    padding-right: 0 !important;
    margin: 0 15px;
  }
}

@media screen and (max-width: 1075px) {

  /* line 7250, ../sass/custom/_custom.scss */
  .preference-btn .cst_btn {
    margin: 0 8px 0 0 !important;
  }

  /* qr screen media */
  .qr_app_content .theme_sub_header .btn_danger {
    margin-bottom: 0;
    margin-right: 0 !important;
  }

  .qr_app_content .theme_sub_header .form-inline {
    display: block;
  }

  .qr_app_content .theme_sub_header .custom-select {
    background-position: 99% center !important;
    min-width: 100%;
    margin-right: 0;
  }

  .qr_app_content .theme_sub_header .label_modifier {
    margin-right: 0;
    min-width: 100%;
    font-size: 14px;
    display: block;
  }

  .qr_app_content .theme_sub_header {
    background-color: transparent;
    padding: 15px 20px;
  }

  .qr_app_content .theme_sub_header .search_btn {
    display: none;
  }

  header.app-header.qr_header {
    background-color: #151B26;
  }

  .qr_header .app-header__logo {
    background-color: #151B26;
    border-right: none;
  }

  .qr_header .app_logo.xl_logo {
    display: none;
  }

  .qr_header .logo_white {
    display: inline-block !important;
  }

  .qr_header .qr_mob_toggle {
    display: inline-block !important;
    background-color: transparent;
    width: 100%;
    text-align: right;
  }

  .qr_header .qr_destop_toggle {
    display: none;
  }

  .sidebar-mini .qr_header .app-sidebar__toggle .toogle_left .fa-bars {
    line-height: 40px;
    color: #9EA7B1;
    font-size: 20px;
  }

  .sidenav-toggled.qr_toggle_sidebar .app-header.qr_header {
    border-bottom: 1px solid #2c2e31;
  }

  .history_block {
    max-width: 100%;
    width: 100%;
    padding: 10px 20px 0;
  }

  .history_block .add_content_block {
    border: none;
    padding: 0;
  }

  .history_block .activity-feed {
    margin-top: 15px;
    padding-left: 0;
  }

  .history_block .h3_title {
    font-size: 18px;
    white-space: nowrap;
  }

  span.cst_border::before {
    content: "";
    background-color: #E1E1E1;
    height: 1px;
    display: inline-block;
    min-width: 96%;
    margin-bottom: 4px;
  }

  .history_block .mob_border_view {
    display: inline-block;
    width: calc(100% - 65px);
  }

  .history_block .title_block {
    width: 65px;
    display: inline-block;
  }

  .history_block img.group_arrow {
    margin-left: 10px;
    display: inline-block;
  }

  .qr_app_content .theme_sub_header .custom-select.search_filter {
    min-width: 100%;
  }

  .client_conference_con .history_block {
    margin-top: 75px;
  }

  .qr_app_content .client_conference_con .theme_sub_header .btn_danger {
    margin-bottom: 10px;
  }

  .app_search_content .history_block {
    padding: 58px 15px 0;
  }

  .app_search_content .history_block .activity-feed {
    margin-top: 10px;
  }

  .history_block .activity-feed .feed-item:after {
    left: -5px;
    width: 10px;
    height: 10px;
  }

  .history_block .activity-feed .feed-item .text_md {
    font-size: 16px;
  }

  /** scanner react app media **/
  .timeslot-btn {
    background: white;
    border: solid 1px #850037 !important;
    padding: 12px !important;
  }

  .qr-app-scroll {
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 100px !important;
  }

  .new_search {
    display: block;
  }

  .previous-history-block .title_block {
    width: 130px !important;
  }

  .previous-history-block .mob_border_view {
    width: calc(100% - 130px) !important;
  }

  .history_warp .history_block .add_content_block {
    border: none;
    padding: 0 35px;
  }

  .history_warp .history_block {
    padding: 10px 0 25px;
  }

  .history_warp .qr_app_content .theme_sub_header {
    padding: 0 20px;
  }

  .chart_section .chart-content {
    margin-bottom: 15px;
  }

  .history_warp .history_pagination {
    margin-top: 20px;
  }

  .history_warp.scan_result_history .history_block .add_content_block {
    padding: 0;
  }

  .history_warp .pagination {
    justify-content: center;
  }

  .scan_result_history .history_block .title_block {
    width: 100px;
    display: inline-block;
  }

  .scan_result_history .history_block .mob_border_view {
    display: inline-block;
    width: calc(100% - 100px);
  }

  .scan_result_history {
    padding: 0 35px;
  }

  /* QQV modal media css */
  .perform_qqv .btn_danger {
    margin-bottom: 0;
  }
}

/** 14-08-2020 **/

@media (max-width: 991px) {

  /* line 7099, ../sass/custom/_custom.scss */
  .userDetails .media-title {
    font-size: 20px;
  }

  /* line 7102, ../sass/custom/_custom.scss */
  .userDetails .UD_tabs .nav-tabs {
    margin-bottom: 20px;
  }

  /* line 7105, ../sass/custom/_custom.scss */
  .userDetails .profile-img {
    width: 85px;
    height: 85px;
    margin-right: 30px;
  }

  /* report media */
  .report_content .sub_head .cst_btn {
    margin-bottom: 0;
  }

  .report_content .grid_view {
    grid-column-gap: 8px !important;
  }

  .activity-feed .feed-item {
    padding-left: 15px;
  }

  .activity-feed {
    padding: 0;
  }

  .tile_ft {
    padding: 0 0 0 15px;
  }

  .print_qr_modal .modal-lg {
    max-width: 100%;
    width: 100%;
    padding: 0 10px;
  }

  .print_qr_modal .modal-body {
    padding: 15px;
  }

  .print_qr_modal .qr_card img {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .print_qr_modal .cst_grid_view {
    margin-bottom: 10px;
  }

  .print_qr_modal .qr_card .card-body {
    padding: 0 5px;
  }

  .print_qr_modal .modal-footer {
    padding: 10px 15px 15px;
  }

  .print_qr_modal .qr_card .sm_time {
    line-height: 24px;
    font-size: 15px;
  }

  .print_qr_modal .qr_card .xs_title {
    line-height: 22px;
  }

  .print_qr_modal .modal-dialog {
    margin: 0;
  }

  /** add area media **/
  .add_info_block {
    padding: 30px 0;
  }

  .report_content_block .select_filter_block .activity-feed .cst_input_group {
    width: 135px;
    margin-left: 0;
  }

  .feed-item .cst_group_append .fa-calendar {
    color: #566975;
  }

  /* 17-08-2020 */
  .quotes_table_content.response_table .circle_img {
    margin: 8px 10px 8px 0;
  }
}

@media (max-width: 991px) {

  /** add area media **/
  .add_info_block {
    padding: 30px 0;
  }

  /* 30-06-2020 */
  .report_content .sub_head .cst_btn {
    min-width: 120px !important;
  }

  .tabProfilePopup .profileMedia {
    display: flex;
  }

  /* tab profile modal */
}

@media (max-width:767px) {

  /** add area media **/
  .add_info_block .h2_title {
    font-size: 28px;
  }

  /* qr media */
  .qr_header .xs_logo {
    display: none;
  }

  .qr_header .qr_mob_toggle {
    margin-top: 0;
  }

  .app-content.qr_app_content {
    margin-top: 50px;
  }

  .history_block .activity-feed .feed-item .text_md {
    font-size: 14px;
  }

  .history_block .activity-feed .feed-item {
    font-size: 12px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .history_block .activity-feed .feed-item:after {
    left: -6px;
    width: 10px;
    height: 10px;
  }

  .history_block {
    padding: 10px 15px 0;
  }

  .qr_app_content .theme_sub_header {
    padding: 15px;
  }

  .history_block .activity-feed .feed-item .list_content {
    max-width: 520px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  .qr_app_content .theme_sub_header .custom-select {
    background-position: 98% center !important;
  }

  .history_block .activity-feed .feed-item:before {
    height: 45px;
  }

  .client_conference_con .history_block .activity-feed .feed-item {
    padding-left: 15px;
  }

  .client_conference_con {
    margin-bottom: 0;
    margin-top: 10px;
  }

  /** 14-08-2020 **/
  .web_pagination .page-item {
    width: 100%;
  }

  .web_pagination .page-link {
    width: 100%;
    height: 30px;
    line-height: 17px;
    margin-bottom: 10px;
    font-size: 14px;
    padding: 5px;
  }

  .web_pagination {
    flex-wrap: wrap-reverse;
    margin-bottom: 5px;
  }

  .page_not_likely {
    margin-top: 0;
  }

  .web_logo {
    text-align: center;
    margin-bottom: 28px;
  }

  .web_h3 {
    font-size: 20px;
    text-align: center;
    line-height: 30px;
  }

  .page_not_likely .web_h4 {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0;
  }

  .web_btn_block {
    text-align: center;
    margin-top: 40px;
  }

  .web_btn_block .cst_btn {
    min-width: 200px;
  }

  .web_modal .modal-body {
    padding: 20px 15px 20px;
  }

  .web_inn_con {
    margin-top: 20px;
  }

  .web_pagination_block {
    margin-top: 32px;
  }

  .web_view .web_modals {
    margin-top: 0;
  }

  .post_comment {
    padding: 15px 0 0;
  }

  .post_comment .web_btn_block {
    margin-top: 50px;
  }

  .web_modal .modal_h4 {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .back_arrow {
    margin-right: 10px;
  }

  .back_arrow img {
    width: 8px;
  }

  .post_comment .label_modifier {
    font-size: 16px;
  }

  .web_three .post_comment {
    padding: 15px 0 0;
  }

  .web_three .web_h5 {
    font-size: 18px;
  }

  .dashboard_page_body .web_view .appcontent_Inner {
    padding: 25px !important;
  }

  .select_radio_wrap .page-item label {
    width: 100%;
    display: block;
  }

  .history_warp .history_block .activity-feed .feed-item:before {
    height: 37px;
  }

  .history_warp .history_block .activity-feed .feed-item:after {
    top: 8px;
  }

  .history_warp .history_block .add_content_block {
    padding: 0 20px;
  }

  .scan_result_history {
    padding: 0 20px;
  }

  .creat_ticket_modal {
    padding-right: 0 !important;
    margin: 0 15px;
  }

  .creat_ticket_modal .modal-body {
    padding: 20px;
  }

  .creat_ticket_modal .modal-header {
    padding: 20px;
  }

  /* QQV modal media css */
  .qqa_btn_blc {
    top: 72px;
    width: 100%;
    left: 0;
  }

  .card.edit_ins_content {
    margin-top: 55px;
  }
}

@media (max-width: 575px) {

  /* line 7112, ../sass/custom/_custom.scss */
  .userDetails .h6_title {
    font-size: 14px;
  }

  /* line 7115, ../sass/custom/_custom.scss */
  .userDetails .media-title {
    font-size: 18px;
  }

  /* line 7118, ../sass/custom/_custom.scss */
  .userDetails .profile-img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  /* line 7123, ../sass/custom/_custom.scss */
  .userDetails .media_details {
    margin-top: 10px;
  }

  .settings-preference .preference-email .setup-label {
    width: 100%;
    margin-bottom: 15px;
    text-align: left;
  }

  /* line 7287, ../sass/custom/_custom.scss */
  .settings-preference .preference-email .preference-article {
    width: 100%;
  }

  /* line 7290, ../sass/custom/_custom.scss */
  .settings-preference .some-email-setup {
    margin: 15px 0 20px 0;
  }

  /* line 7293, ../sass/custom/_custom.scss */
  .settings-preference .container {
    padding: 0;
  }

  /* report media */
  .report_content .grid_view {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }

  .report_content .sub_head .cst_btn {
    min-width: 100px !important;
  }

  .select_filter_block .cst_input_group {
    width: 135px;
  }

  /* qr media */
  .history_block .h3_title {
    font-size: 16px;
  }

  span.cst_border::before {
    min-width: 93%;
  }

  .client_conference_con .history_block .h3_title {
    line-height: 40px;
  }

  .history_block {
    padding: 50px 15px 0;
  }

  .print_qr_modal .cst_grid_view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .print_qr_modal .pagination {
    float: inherit;
    justify-content: center;
  }

  .print_qr_modal .cst_pagination {
    float: inherit;
    margin-bottom: 15px;
    margin: 0 auto;
  }

  .print_qr_modal .btn_block {
    text-align: center;
  }

  .print_qr_modal .modal-body {
    padding: 15px 10px;
  }

  .print_qr_modal .modal-footer {
    padding: 0 10px 10px;
  }

  .print_qr_modal .modal-footer .cst_btn {
    margin: 0 0 10px;
  }

  .setUnavailable_modal .modal-dialog {
    max-width: 100%;
    margin: 0 auto;
  }

  .setUnavailable_modal .modal-dialog {
    padding: 0 15px;
  }

  /** 14-08-2020 **/
  .web_logo img {
    width: 160px;
  }

  /* 17-08-2020 */
  .chi_surveys .grid_card {
    min-height: auto;
  }

  .survey_response .response_sub_head {
    padding: 40px 20px 0;
  }

  .app-nav .app-menu__item {
    padding: 8px 0 8px 0;
  }

  .app-nav .media {
    align-items: center;
  }

  /* qqv modal media css */
  .perform_qqv {
    padding: 0 !important;
    margin: 0 25px;
  }

  .card.edit_ins_content {
    margin-top: 30px;
  }
}

@media (max-width: 520px) {
  .areaTypeModal .add_content_block .form_content .grid_view {
    grid-template-columns: 100px 100px 100px 1fr;
  }
}

@media (max-width: 480px) {
  .client_conference_con {
    margin-bottom: 0px;
    margin-top: 0;
  }

  .client_conference_con .history_block {
    padding: 0;
    margin-top: 60px;
    display: inline-block;
  }

  .client_conference_con .history_block .add_content_block {
    padding: 0 20px;
  }

  .qr_header .logo_white {
    width: 120px;
  }

  .app-header.qr_header {
    padding: 10px 0;
  }

  footer.cus_ffoter_block {
    padding: 12px 15px;
  }

  .qr_app_content .theme_sub_header {
    top: 65px;
  }

  .qr_app_content .theme_sub_header .label_modifier {
    line-height: 24px;
  }

  .select_filter_block .cst_input_group {
    width: 135px;
  }

  .feed-item:before {
    content: none;
  }

  .activity-feed .feed-item:after {
    content: none;
  }

  .activity-feed .feed-item {
    padding-left: 0;
    padding-bottom: 10px;
  }

  .tile_ft {
    padding: 0;
    text-align: center;
    margin-bottom: 0;
  }

  .feed-item .btn_block .cst_btn {
    margin-bottom: 0;
  }

  .select_filter_block .tile {
    padding: 20px 15px;
  }

  .feed-item .form-inline {
    display: inherit;
  }

  .select_filter_block .cst_input_group {
    width: 100%;
    margin-bottom: 10px;
  }

  .feed-item .btn_block {
    text-align: center;
  }

  .filter_select {
    display: inherit;
    text-align: center;
  }

  .filter_select .custom-select {
    width: 100%;
    margin-bottom: 10px;
  }

  .select_filter_block .form_title {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .select_filter_block .cst_mt {
    margin-top: 0;
  }

  .select_filter_block .des {
    display: none;
  }

  .tile_ft .btn_danger {
    margin-bottom: 0;
  }

  .report_content .sub_head .cst_btn {
    min-width: 70px !important;
  }

  .edit_inspection .inner_card .ins_form_btn .cst_btn {
    min-width: auto !important;
  }

  .report_content .filter_select .multi_Select {
    width: 100%;
  }

  .report_content_block .select_filter_block .activity-feed .cst_input_group {
    width: 100% !important;
  }

  .report_content_block .select_filter_block .activity-feed .cst_input_group label {
    width: 100% !important;
  }

  /** 14-08-2020 **/
  .web_logo img {
    width: 150px;
  }

  /* 17-08-2020 */
  .report_content .sub_head {
    display: block;
  }

  .chi_surveys .custom_pagination .pagination {
    justify-content: center;
  }

  .chi_surveys .custom_pagination {
    float: inherit;
  }

  .app-nav .app-menu__label {
    display: none;
  }

  .app-nav .app-nav__item {
    padding-right: 10px !important;
  }

  .app-nav .mob_header {
    padding: 8px 10px !important;
  }

  .app-header__logo {
    padding: 0 3px;
  }

  .app-header.mob_header {
    padding: 8px 10px !important;
  }

  .history_warp.qr_app_content .theme_sub_header {
    top: 0;
  }

  .history_warp .history_block .add_content_block {
    padding: 0 20px;
  }

  .history_warp .history_block .activity-feed .feed-item:before {
    height: 34px;
  }

  .history_warp.qr_app_content .theme_sub_header .custom-select {
    min-width: 100%;
  }

  .history_warp .history_block .activity-feed {
    margin-top: 15px;
  }

  .create_ticket_btn {
    text-align: center;
  }

  .qqa_btn_blc {
    top: 65px;
  }
}

.export_chi_btn {
  align-items: center;
  margin-bottom: 15px;
}

@media (max-width: 475px) {

  /** add area media **/
  .add_content_block {
    padding: 15px;
  }

  /** scan qr **/
  .scanner_content .scanner_title {
    top: 60px;
  }

  span.ft_content {
    font-size: 10px;
  }

  /* qr media */
  .history_block {
    padding: 0 15px 0;
  }

  .feed-item:before {
    content: none;
  }

  .history_block .activity-feed .feed-item:after {
    content: "";
  }

  .history_block .activity-feed .feed-item:before {
    content: "";
  }

  span.cst_border::before {
    min-width: 92%;
  }

  .qr_app_content .theme_sub_header .label_modifier {
    line-height: 24px;
  }

  .conference_head {
    margin-bottom: 15px;
  }
}

@media (max-width: 445px) {
  .add_content_block .form_content .grid_view {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    margin-bottom: 10px;
  }

  .add_content_block .form_content .trash_icn_block {
    width: 50px;
    margin: 0 auto;
  }

  .time_services .cst_btn {
    padding: 15px 10px;
  }

  /* list view media */
  .app_screen_outer .h4_title {
    font-size: 16px;
    line-height: 25px;
  }

  .list_view .sm_title {
    font-size: 16px;
  }

  .client_login.list_view {
    padding-top: 80px;
  }

  .client_login.list_view .input_box {
    padding: 0.55rem .55rem;
  }

  .list_view .Schedule_con .h4_title {
    margin-bottom: 15px;
  }

  .list_view .Schedule_con .card_footer .cst_btn {
    padding: 0.55rem 0.55rem;
    margin-bottom: 0;
  }

  .list_view .card_footer .ft_content {
    padding: 15px 0 0 !important;
  }

  .list_view .card_footer .cst_btn {
    padding: .75rem 0.75rem;
    margin-bottom: 0;
  }

  .list_view .card_footer {
    position: fixed;
    padding: 0 15px 15px;
    background-color: #fff;
  }

  .list_view .login_form_content.Schedule_con {
    width: 100%;
    max-height: 1000px;
    overflow: auto;
    padding-bottom: 120px;
  }

  /*list view mwdia */
  .areaTypeModal .add_content_block .form_content .grid_view {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 439px) {
  .setUnavailable_modal .modal-footer .cst_btn {
    min-width: 160px;
  }

  .form_input_block .form-inline {
    display: block;
  }

  .chi_report .des {
    display: none;
  }

  .chi_report .form_input_block {
    max-width: 100%;
    width: 100%;
  }

  .chi_report .cst_input_group {
    width: 100%;
    margin-bottom: 10px;
  }

  .report_content .sub_head {
    display: inline-block;
  }

  .chi_content .head_btn_block {
    margin-top: 10px;
  }
}

@media (max-width: 420px) {
  .client_conference_con .history_block .activity-feed .feed-item .text_md {
    font-size: 14px;
  }

  .history_block .activity-feed .feed-item {
    padding-bottom: 10px;
  }

  .qr_app_content .theme_sub_header .label_modifier {
    line-height: 24px;
    margin-bottom: 6px;
  }

  .client_conference_con .history_block .activity-feed {
    margin-top: 0;
  }

  .client_conference_con .history_block .h3_title {
    margin-bottom: 0;
  }

  .history_block .qr_app_content .theme_sub_header {
    padding: 15px 15px 5px;
  }

  .history_block .activity-feed .feed-item:before {
    height: 30px;
  }

  .conference_head {
    margin-bottom: 10px;
  }

  .client_conference_con .input_btn .input_modifier {
    margin-bottom: 10px;
    height: 40px;
  }

  .conference_header.qr_header .logo_white {
    width: 120px;
  }

  .qr_header .mob_header {
    padding: 10px 0;
  }

  .conference_header.app-header.qr_header {
    padding: 10px 0;
  }

  .client_conference_con .history_block .activity-feed .feed-item {
    padding-bottom: 10px;
  }

  .client_conference_con .history_block .activity-feed .feed-item:before {
    height: 35px;
  }

  .qr_app_content .theme_sub_header .custom-select {
    min-height: 35px;
  }

  .history_block .activity-feed {
    margin-top: 5px;
  }

  .app_search_content .history_block .activity-feed .feed-item {
    padding-bottom: 15px;
  }

  .app_search_content .history_block {
    padding: 50px 15px 0;
  }

  /* final view */
  .client_conference_final .sm_title {
    font-size: 14px;
  }

  .history_warp .history_block .activity-feed .feed-item {
    padding-bottom: 15px;
  }

  .scan_result_history .conference_head {
    display: block;
    text-align: center;
  }

  .scan_result_history .conference_head .h4_title {
    margin-bottom: 12px;
  }

  .scan_result_history .search_btn {
    text-align: center;
  }

  .history_warp .history_pagination .page-link {
    padding: 0.3rem 0.55rem;
    font-size: 14px;
  }
}

@media (max-width: 411px) {
  .client_conference_con {
    margin-top: 0;
  }

  .client_conference_con .history_block {
    margin-top: 60px;
  }

  .qr_app_content .client_conference_con .theme_sub_header .btn_danger {
    margin-bottom: 5px;
  }

  /*.client_conference_con .history_block .h3_title {*/
  /*margin-bottom: 5px;*/
  /*}*/
}

@media (max-width: 412px) {
  .client_conference_con {
    margin-top: 0;
  }

  .history_block .h3_title {
    margin-bottom: 0;
    font-size: 14px;
  }

  .history_block .activity-feed .feed-item {
    padding-bottom: 6px;
  }

  .history_block {
    padding: 32px 15px 0;
  }

  .history_block .activity-feed {
    margin-top: 5px;
  }

  .qr_app_content .theme_sub_header {
    top: 55px;
  }
}

@media (max-width: 414px) {
  .client_conference_con {
    margin-bottom: 40px;
    margin-top: 0;
  }
}

@media (max-width: 400px) {
  .setUnavailable_modal .modal-footer .cst_btn {
    margin: inherit;
    background: inherit;
    color: #fff;
    min-width: 140px;
  }

  /* final view */
  .client_conference_final .history_block .activity-feed .feed-item .list_content {
    max-width: 290px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
  }
}

@media (max-width: 385px) {
  .add_content_block .form_content .trash_icn_block {
    width: auto;
  }

  span.cst_border::before {
    min-width: 90%;
  }

  .conference_head .h4_title {
    font-size: 16px;
  }

  .client_conference_con span.cst_border::before {
    min-width: 89%;
  }

  .client_conference_con .history_block .activity-feed .feed-item:last-child {
    padding-bottom: 0;
  }

  .client_conference_con {
    margin-top: 0;
  }

  .client_conference_con .history_block .activity-feed .feed-item {
    padding-bottom: 10px;
  }

  .client_conference_con .history_block .activity-feed .feed-item:before {
    height: 35px;
  }

  .time_services .grid_viw {
    grid-column-gap: 6px;
  }

  .time_services .cst_btn {
    padding: 10px 6px;
    font-size: 13px;
  }
}

@media (max-width: 376px) {

  /** add area media **/
  .add_content_block .modal-footer {
    display: block;
    text-align: center;
  }

  .add_content_block .modal-footer .cst_btn {
    margin-left: 0;
    margin-right: 0;
  }

  .add_content_block .btn_outline_secondary {
    margin-top: 10px;
  }

  .add_content_block .modal-footer {
    padding: 20px 0;
  }

  .add_content_block .form_content .grid_view {
    grid-column-gap: 10px;
  }

  /* 17-08-2020 */
  .chi_surveys.chi_content .head_btn_block {
    display: block;
  }

  .report_content .cst_select_block .custom-select {
    margin-bottom: 10px;
  }

  .survey_response .user_profile_details .user_profile_details_sec .user_status {
    padding: 6px 0 0;
  }
}

@media (max-width: 375px) {
  .qr_app_content .theme_sub_header {
    padding: 10px 15px 5px;
  }

  .qr_app_content .theme_sub_header .custom-select {
    min-height: 35px;
  }

  .qr_app_content .theme_sub_header .label_modifier {
    margin-bottom: 5px;
  }

  .qr_app_content .theme_sub_header {
    top: 60px;
  }

  .history_block .activity-feed {
    margin-top: 3px;
  }

  .history_block .activity-feed .feed-item {
    padding-bottom: 7px;
  }

  .history_block {
    padding: 45px 15px 0;
  }

  .history_block .h3_title {
    margin-bottom: 0;
  }

  .app_search_content .history_block .activity-feed .feed-item {
    padding-bottom: 15px;
  }
}

@media (max-width: 374px) {
  .add_content_block .form_content .grid_view {
    grid-template-columns: 75px 75px 75px 1fr;
    grid-column-gap: 10px;
  }

  .client_conference_con .history_block .activity-feed .feed-item .text_md {
    font-size: 14px;
  }

  .qr_app_content .theme_sub_header {
    padding: 10px 15px 5px;
  }

  .history_block .activity-feed .feed-item {
    padding-bottom: 4px;
  }

  .history_block .activity-feed .feed-item:before {
    height: 25px;
  }

  .history_block .activity-feed .feed-item:after {
    left: -5px;
    width: 8px;
    height: 8px;
  }

  .app_search_content .history_block .activity-feed .feed-item:before {
    height: 32px;
  }

  /* list view media */
  .setUnavailable_modal .modal-footer .cst_btn {
    min-width: 120px;
  }
}

@media (max-width: 360px) {

  /** add area media **/
  .add_content_block .form_content .grid_card {
    margin-bottom: 10px;
  }

  /* 17-08-2020 */
  .survey_response .tickets_user_profile_body .user_activity_conent .attachment_sec .attachments_files .attachments__file_box {
    min-width: 100%;
    height: auto;
  }

  .survey_response .comment_buttons .btn_danger {
    margin-right: 0 !important;
  }

  .app-sidebar__toggle {
    padding: 0 10px 0 10px !important;
    line-height: 34px;
    width: 35px;
    height: 35px;
  }

  .sidebar-mini .app-sidebar__toggle .toogle_left .fa-bars {
    line-height: 30px;
    font-size: 16px;
  }

  .app-sidebar__toggle {
    margin: 0 !important;
  }

  .xs_logo {
    width: 45px !important;
  }

  .app-nav .app-nav__item {
    padding: 0 10px;
  }

  .app-header .user_menu_dropdown .user_circle {
    width: 30px;
    height: 30px;
  }

  .app-nav .company_name {
    margin-left: 5px;
    font-size: 14px;
  }
}

@media (max-width: 345px) {
  .report_content .grid_view {
    display: grid;
    grid-template-columns: 1fr !important;
  }

  /** add area media **/
  .add_content_block .form_content .grid_view {
    grid-template-columns: 1fr 1fr;
  }

  .add_content_block .form_content .trash_icn_block {
    width: 100%;
  }

  .qr_app_content .client_conference_con .theme_sub_header .search_btn {
    display: none;
  }

  span.cst_border::before {
    min-width: 87%;
  }

  /* final view */
  .client_conference_final .history_block .activity-feed .feed-item .list_content {
    max-width: 240px;
  }

  .app-content.qr_app_content.client_conference_block {
    margin-top: 0;
    padding: 0;
  }

  .conference_header.qr_header .logo_white {
    width: 100px;
  }

  .client_conference_con span.cst_border::before {
    min-width: 85%;
  }

  .client_conference_con {
    margin-bottom: 0;
    margin-top: 10px !important;
  }

  .history_block .activity-feed .feed-item {
    padding-bottom: 5px;
  }

  .qr_header .logo_white {
    width: 110px;
  }

  .app-header.qr_header {
    padding: 6px 0;
  }

  .qr_app_content .theme_sub_header {
    top: 47px;
  }

  .qr_header .app-header__logo {
    line-height: 40px;
  }

  .history_block .h3_title {
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
  }

  .client_conference_con .history_block .activity-feed .feed-item {
    padding-bottom: 6px;
  }

  .conference_head {
    margin-bottom: 5px;
  }

  .client_conference_con .input_btn .input_modifier {
    margin-bottom: 6px;
    height: 35px;
  }

  .client_conference_con .history_block .activity-feed .feed-item:before {
    height: 30px;
  }

  .client_conference_con .history_block {
    margin-top: 50px !important;
  }

  .client_conference_con .history_block .h3_title {
    line-height: 28px;
  }

  .history_block {
    padding: 41px 15px 0;
  }

  .qr_app_content .theme_sub_header .custom-select {
    margin-bottom: 5px;
  }

  header.app-header.qr_header {
    padding: 5px 10px;
  }

  .history_block .activity-feed .feed-item {
    padding-bottom: 2px;
  }

  .qr_app_content .theme_sub_header .label_modifier {
    margin-bottom: 3px;
    line-height: 20px;
  }

  footer.cus_ffoter_block {
    padding: 10px 15px;
  }

  .app_search_content .history_block .activity-feed .feed-item {
    padding-bottom: 10px;
  }

  .qr_app_content .app_search_content .theme_sub_header {
    top: 50px;
  }
}

/*** // userDetails screen media here ***/



/* 03-07-2020 */
.form_info .custom-checkbox .fa {
  color: #364347;
  font-size: 20px;
}

.form_info .custom-checkbox .icn_lock {
  display: inline-block;
}

.form_info .custom-checkbox .icn_unlock {
  display: none;
}

.add_des_btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  transform: translate(0, 0);
  min-width: auto;
  padding: .8rem .8rem .8rem;
  height: 56px;
}

.add_des_btn .fa {
  color: #364347;
  font-size: 18px;
}

.description-collapse {
  width: 100%;
}

.description_card .card-body.text-center {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  text-align: center !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* user profile popup css */
.tabProfilePopup {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.tabProfilePopup .popuptext {
  visibility: hidden;
  width: 340px;
  background-color: #fff;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 0;
  position: absolute;
  z-index: 99999999999999;
  bottom: -139px;
  left: 61px;
  margin-left: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 16px;
  top: auto;
}

/* Toggle this class - hide and show the popup */
.tabProfilePopup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.tabProfilePopup .modal_content {
  background: #fff;
  border-radius: 4px;
}

.tabProfilePopup .profileMedia .media_title {
  text-align: left;
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  color: #151b26;
  font-weight: 600;
}

.tabProfilePopup .profileMedia {
  align-items: center;
}

.tabProfilePopup .profileMedia .circle_img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: transparent;
  display: inline-block;
  color: #fff;
  line-height: 58px;
  border: 1px solid #ececec;
}

.tabProfilePopup .userEmail {
  display: block;
  text-align: left;
  color: #2b3034;
}

.tabProfilePopup .media-body {
  margin: 0px 0px 0 20px;
}

.tabProfilePopup .modal-footer {
  justify-content: space-between;
}

.tabProfilePopup .ft_icons img {
  width: 20px;
  height: 20px;
}

.tabProfilePopup .ft_icons .icn_link {
  margin-right: 10px;
}

.tabProfilePopup .ft_icons .icn_link:last-child {
  margin-right: 0;
}

.tabProfilePopup .profileMedia {
  align-items: center;
  display: flex;
}

/* tab profile modal */
@media (max-width: 991px) {
  .tabProfilePopup .profileMedia {
    display: flex;
  }
}

@media (max-width: 480px) {
  .tabProfilePopup .popuptext {
    width: 270px;
    bottom: -139px;
    left: 0;
  }
}


/* switch button*/
.theme_switch_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #2b3034;
}

.theme_switch_btn>label {
  position: relative;
  display: inline-block;
  width: auto;
  height: 20px;
  line-height: 20px;
  margin-bottom: 0;
}

.theme_switch_btn>label>input {
  opacity: 0;
  width: 0;
  margin-right: 38px;
}

.theme_switch_btn>label>input:checked+*:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/*.theme_switch_btn > label > input:focus + * {*/
/*box-shadow: 0 0 0 0.2rem rgba(168,179,188,.25);*/
/*}*/

.theme_switch_btn>label>input+* {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a8b3bc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 36px;
  border-radius: 24px;
}

.theme_switch_btn>label>input+*::before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.theme_switch_btn>label>input:checked+* {
  background-color: #850037;
}

.switch_title {
  margin-right: 6px;
  display: inline-block;
}

/* Media */
@media (max-width: 580px) {
  .mob_txt_hide {
    display: none;
  }

  .theme_switch_btn>label>input+* {
    width: 30px;
  }

  .theme_switch_btn>label {
    height: 17px;
  }

  .theme_switch_btn>label>input:checked+*:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  .theme_switch_btn>label>input+*::before {
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
  }

  .theme_switch_btn>label>input {
    margin-right: 29px;
  }

  .switch_title {
    margin-right: 4px;
  }

  .theme_switch_btn {
    font-size: 13px;
  }
}

.select-disable-icon .custom-select {
  background-image: none;
}

.inspectionLoader span {
  display: flex !important;
}

.web_pagination_block .page-item a {
  cursor: pointer !important;
}

/* start dev css styling*/
.disabled_checkbox .custom-control-label::after {
  cursor: not-allowed !important;
}

.full_width {
  width: 100%;
}

/* end dev css styling */
.job_wide_disable {
  pointer-events: none !important;
  background-color: #f1f1f1 !important;
  opacity: .5 !important;
}

.yellow-draft-color {
  background-color: #ffff0052;
}

.yellow-draft-color:hover {
  background-color: rgb(222, 222, 168, 1);
}

.prior-ticket-color {
  background-color: #898788 !important;
  color: #FFF;
}

.prior-ticket-color .react-td-element {
  color: #fff !important;
}

.prior-ticket-color:hover {
  color: #FFF;
  background-color: #ff660069;
}

/* custom date-picker  */

#custom_date_picker .custom_label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

#custom_date_picker .cst_group_append {
  position: absolute;
  right: 15px;
  height: 36px;
  top: 0;
}

#custom_date_picker .calender_icon {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none !important;
  flex: inherit;
  background-color: transparent;
  height: 36px;
}

#custom_date_picker .quotes_input {
  width: 100%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* custom date-picker  */

/* text overflow issue  */

.category_text_overflow .gridStyle {
  position: relative;
}

.category_text_overflow .gridInlineBlc {
  display: inline-block;
  float: left;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.category_text_overflow .grdTitle {
  width: calc(100% - 60px);
  float: left;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.category_text_overflow .icnBlc {
  float: right;
  display: inline-block;
  padding-right: 10px;
}

/* text overflow issue  */

/* ticket preview alignment */

.user_profile_details .user_profile_details_sec .assign_location .assigned_to_div label {
  text-align: left;
}

.ticket_form .category_select {
  width: 100%;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1199px) {
  #user_filter .media_padding {
    padding-right: 0px !important;
  }

  /* readonly */

  .quotes_time .form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
  }
}

/* view Quotes popup css */
.view_quotes_timeline_wrap .step {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}

.view_quotes_timeline_wrap .v-stepper {
  position: relative;
}

/* regular step */
.view_quotes_timeline_wrap .step .circle {
  background-color: white;
  border: 2px solid #30c13d;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  line-height: 17px;
  display: inline-block;
  text-align: center;
}

.view_quotes_timeline_wrap .step .line {
  top: 22px;
  left: 10px;
  height: 100%;
  position: absolute;
  border-left: 2px solid #30c13d;
}

.view_quotes_timeline_wrap .step.completed .circle {
  visibility: visible;
  background-color: #30c13d;
  border-color: #30c13d;
}

.view_quotes_timeline_wrap .fa-check {
  color: #fff;
}

.view_quotes_timeline_wrap .fa-check:before {
  font-size: 11px;
}

.view_quotes_timeline_wrap .step.completed .line {
  border-left: 2px solid #30c13d;
}

.view_quotes_timeline_wrap .step.active .circle {
  visibility: visible;
  border-color: #30c13d;
  background-color: #30c13d;
}

.view_quotes_timeline_wrap .step.empty .circle {
  visibility: hidden;
}

.view_quotes_timeline_wrap .step.empty .line {
  top: 0;
  height: 150%;
}

.view_quotes_timeline_wrap .step:last-child .line {
  border-left: 3px solid white;
  z-index: -1;
  display: none;
}

.view_quotes_timeline_content {
  margin-left: 0;
  display: inline-block;
  width: 100%;
}

.view_quotes_list {
  margin-bottom: 12px;
}

.view_quotes_list td {
  padding: 0;
}

.table.timline_table_th th {
  border-top: none;
}

.table.timline_table_th thead th {
  border-bottom: 1px solid #dee2e6;
  font-size: 13px;
}

.text_success {
  color: #30c13d;
}

.text_warning {
  color: #d28500 !important
}

.view_quotes_cc .modal-title {
  color: #151b26;
  font-weight: 600;
}

.view_quotes_cc .modal-header {
  border-bottom: none;
}

.view_quotes_cc .table th {
  padding: 0.65rem 1rem;
}

.view_quotes_cc .table td {
  padding: 0 1rem;
}

.view_quotes_list td {
  font-size: 13px;
}

.view_quotes_cc {
  padding-right: 0 !important;
}

.view_quotes_cc .modal-body {
  padding: 1rem 0;
}

.list_empty_th {
  width: 6%;
}

.status {
  width: 20%;
}

.requested_by {
  width: 20%;
}

.requested_on {
  width: 20%;
}

.amount {
  width: 20%;
}

/* view Quotes popup css end */
/* view quotes media */
@media (max-width:991px) {
  .view_quotes_list {
    margin-bottom: 0;
  }

  .view_quotes_timeline_wrap .step .line {
    min-height: 48px;
  }

  .table.timline_table_th thead th {
    font-size: 12px;
  }
}

@media (max-width:575px) {
  .view_quotes_cc .table th {
    padding: 0.65rem .55rem;
  }

  .view_quotes_cc .table td {
    padding: 0 .45rem;
  }

  .view_quotes_cc {
    margin: 0 5px;
  }

  .view_quotes_timeline_wrap .step .circle {
    width: 20px;
    height: 20px;
    line-height: 16px;
  }

  .view_quotes_timeline_wrap .step .line {
    top: 20px;
    left: 9px;
  }

  .table.timline_table_th thead th {
    font-size: 11px;
  }

  .view_quotes_list td {
    font-size: 11px;
  }

  .view_quotes_timeline_wrap .step .line {
    min-height: 42px;
  }
}

@media (max-width:420px) {
  .table.timline_table_th thead th {
    font-size: 11px;
  }

  .view_quotes_list td {
    font-size: 11px;
  }

  .view_quotes_timeline_wrap .step .line {
    min-height: 42px;
  }

  .view_quotes_cc .table td {
    padding: 0 .35rem;
  }

  .view_quotes_timeline_wrap .step .circle {
    width: 19px;
    height: 19px;
    line-height: 16px;
  }

  .view_quotes_timeline_wrap .step .line {
    top: 19px;
    left: 9px;
  }

  .view_quotes_cc {
    margin: 0 3px;
  }
}

@media (max-width:360px) {
  .view_quotes_list td {
    font-size: 10px;
  }

  .view_quotes_timeline_wrap .step .line {
    top: 17px;
  }
}

/* view quotes media end */
.text-center {
  text-align: center !important;
}

.view_quotes_list {
  margin-bottom: 12px !important;
}

#progress_status_bar .quotes_table_content .line {
  border-left: 3px solid #30c13d !important;
  z-index: 1 !important;
  display: block !important;
}

#progress_status_bar .quotes_table_content:last-child .line {
  border-left: 3px solid #ffffff !important;
  z-index: -1 !important;
  display: none !important;
}

#quotes_table_align .ReactTable .rt-tbody .rt-td:last-child {
  display: flex;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */

}

.quote_status_loader .loader_btn_block {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: auto;
}

#expired_circle .circle {
  background-color: #dc3545;
  border-color: #dc3545;
}

.type-dark {
  white-space: nowrap;
}

.qsl_main_content .Qsl_table_font {
  font-size: 14px !important;
}

.qsl_main_content .Qsl_table_head {
  font-size: 16px !important;
  letter-spacing: 0.7px;
}

.qsl_main_content .qqv_status_bg {
  background-color: #850037;
}

.qsl_main_content .total_sale_qsl {
  letter-spacing: 0.3px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 25px;
  padding: 4px 10px;
  background: #EA4C89;
}

@media only screen and (max-width: 799px) {
  .graph_responsive #mobile_screen_graph {
    display: block;
  }

  .graph_responsive #desktop_screen_graph {
    display: none !important;
  }
}

@media only screen and (min-width: 800px) {
  .graph_responsive #mobile_screen_graph {
    display: none !important;
  }

  .graph_responsive #desktop_screen_graph {
    display: block;
  }
}

.duo_date_text {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
  margin-left: 7px;
  color: #566975;
}

.duo_date_title {
  position: relative;
  margin-bottom: 13px;
  color: #566975;
  font-size: 17px;
}

.scoreText {
  margin-bottom: 0px;
  margin-top: -5px;
  margin-left: 15px;
}

.chi_status {
  text-transform: capitalize;
  color: #ffffff;
  border-radius: 25px !important;
  padding: 6px 28px !important;
  font-weight: 600 !important;
}

.chi_open_status {
  background: #30c13d;
}

.chi_close_status {
  background: #ff0000;
}

#chiservys_block .chi_surveys .app_inner_title {
  padding: 0;
  margin-right: 15px;
}

.chiScoreStyle {
  font-size: 20px;
  margin-left: 35px;
  margin-top: -7px;
  font-weight: 600;
  margin-bottom: 0px;
}


#chi_preview .custom_transition {
  transition: 0.3ms all;
}


#reassign_select .custom_option {
  color: #89073c !important;
}

#percentage_content .custom_option {
  font-weight: bold;
}

#percentage_select_color .custom_select_option {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
  /* border: 1px solid #000000; */
}

.color_change {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  margin: auto;
}

.color_change[id="orange"] {
  background-color: #ffa500;
  color: #ffa500;
}

.color_change[id="red"] {
  color: #ff0000;
  background-color: #ff0000;

}

.color_change[id="light_green"] {
  color: #90ee90;
  background-color: #90ee90;

}

.color_change[id="green"] {
  color: #00ff00;
  background-color: #00ff00;

}

.color_change[id="yellow"] {
  color: #ffff00;
  background-color: #ffff00;

}

.color_change[id="blue"] {
  color: #0000ff;
  background-color: #0000ff;

}

.viewIns_btn .custom_hover {
  transition-duration: 400ms;
}

.viewIns_btn .custom_hover:hover {
  color: #ffffff;
  background-color: #89073c;
  transition-duration: 400ms;
}

#area_table_head .rt-td.react-td-element {
  overflow: hidden;

}

#kpi_theme .grid_view {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

#kpi_theme .grid_card .cst_para {
  line-height: 22px;
}

#kpi_graph_cards .grid_view {
  grid-template-columns: 1fr 1fr;
}

#kpi_dashboard .app_inner_title {
  padding: 0 !important;
}

.readmore_graph {
  transition: .15s ease-in-out;
  transition-duration: .4s;
}

.noData_found .h3_title {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translate(-50%, -50%);
}

.kpidashboard_tabs {
  padding-top: 18px;
  float: left;
  width: 100%;
  background: #b3caec;
  margin-bottom: 23px;
}

.kpidashboard_tabs .tab_title {
  color: #990d46;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
}

.top_inspec_table {
  height: 350px;
  overflow: auto;
}

.top_inspec_table::-webkit-scrollbar {
  width: 10px;
}

.top_inspec_table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #850037;
  border-radius: 10px;
}

.top_inspec_table::-webkit-scrollbar-thumb {
  background: #cf2d70;
  border-radius: 10px;
}

.top_inspec_table::-webkit-scrollbar-thumb:hover {
  background: #850037;
}

.overview_company {
  background-color: #89073c;
  border: 1px solid #89073c;
  color: #ffffff;
  border-radius: 25px;
  font-size: 17px;
  font-weight: 600;
  padding: 3px 25px;
}

.kpitab_row {
  margin-top: 7px;
  background: #C7CDD1;
  display: flex;
  align-items: center;
}

.kpitab_col {
  width: 100%;
  background: #C7CDD1;
  float: left;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  transition-duration: 400ms;
}

.kpitab_col.active {
  background: #89073c;
  transition-duration: 400ms;
}

.tab_title.active {
  color: #ffffff !important;
  transition-duration: 400ms;
}

#kpi_dashboard_tabs .search_filter_cont {
  margin-top: 0px !important;
}

.kpidashboard_tab .tab_title {
  margin-top: 0.5rem;
  color: #151B26;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  transition-duration: 400ms;
  padding: 10px 0;
}

#kpi_ticket_dashboard .grid_view {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

#kpi_dashboard .report_content .react_times_button.time_picker_preview {
  padding: 0 0 0px !important;
}

#kpi_dashboard_tabs .admin_dashboard .app_inner_title {
  padding: 0px 0 0px !important;
}


.geraldine_color .CircularProgressbar-path {
  stroke: #FB9592 !important;
}

.wattle_color .CircularProgressbar-path {
  stroke: #C8D94A !important;
}

.wheatfield_color .CircularProgressbar-path {
  stroke: #CFB6F2 !important;
}

.casablanca_color .CircularProgressbar-path {
  stroke: #F2A950 !important;
}

.pacific_blue_color .CircularProgressbar-path {
  stroke: #0098c7 !important;
}

.lime_green_color .CircularProgressbar-path {
  stroke: #FF7AD9 !important;
}

.pale_pink_color .CircularProgressbar-path {
  stroke: #FF99CC !important;
}

.blue_purble_color .CircularProgressbar-path {
  stroke: #9999FF !important;
}

.fluorescent_blue_color .CircularProgressbar-path {
  stroke: #47FFFE !important;
}

#kpi_dashboard .filter_block .list_filter .list_link:last-child {
  border-right: 0;
  width: 110px;
}

#kpi_dashboard .grid_card {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 15px;
  transition: all 0.3s;
}

#kpi_dashboard .grid_card:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}

#kpi_dashboard .grid_card .h4_title {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
  color: #454444;
  margin-bottom: 10px;
  height: 30px;
}

#kpi_dashboard .grid_card .sub_title {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  color: #8D8D8D;
}

#kpi_dashboard .grid_card .count {
  font-size: 26px;
  color: #000000;
  margin-bottom: 0;
}

#kpiAccountList .css-g1d714-ValueContainer {
  flex-wrap: nowrap;
}

.admin_dashboard#kpi_dashboard .calc_content li {
  padding: 10px 0px !important;
  width: 50px;
  text-align: center;
}

/* #kpi_dashboard_tabs .search_filter_cont .btn.btn_danger{
  background-color: #727df5 !important;
  color: #fff !important;
  border-color: #727df5;
  transition: all 0.3s;
}
#kpi_dashboard .btn.btn_danger{
  background-color: #727df5 !important;
  color: #fff !important;
  border-color: #727df5;
  transition: all 0.3s;
}
#kpi_dashboard_tabs .tab_header {
  background-color: #dae5ff !important;
 
}
#kpi_dashboard_tabs .search_filter_cont .btn.btn_danger:hover {
  background-color: #4747A1 !important;
  color: #fff !important;
  border-color: #4747A1;
  transition: all 0.3s;
}
#kpi_dashboard .head_btn_block .custom_title{
  background-color: #727df5 !important;
  color: #ffffff !important;
}
#kpi_dashboard .filter_block .list_filter .active {
  color: #fff;
  background-color: #727df5;
  margin: 0;
}
#kpi_dashboard .filter_block .list_filter {
  border: 1px solid #727df5 !important;
}
#kpi_dashboard .filter_block .list_filter .list_link:hover,
 #kpi_dashboard .filter_block .list_filter .list_link:active,
  #kpi_dashboard .filter_block .list_filter .list_link:focus {
  color: #fff;
  background-color: #727df5 !important;
}
#kpi_dashboard .calc_content li:hover {
  background-color: #727df5 !important;
}
#kpi_dashboard .cal_block {
  border: 1px solid #727df5;

}
#kpi_dashboard .graph_tabs .chart_title {
  margin-bottom: 20px !important;
}

#kpi_dashboard #chart_sec{
  background: #DAE6FF !important;
    border: 1px solid #727df5;
}
#kpi_dashboard #table_head th{
  color: #ffffff !important;
  background: #727df5;
    border: 1px solid #727df5;
}
#kpi_dashboard .averge_score_graph tr:nth-child(1){
 background: #EEA1C0;
}
#kpi_dashboard .averge_score_graph tr:nth-child(2){
  background: #D6EFE4;
 }
 #kpi_dashboard .averge_score_graph tr:nth-child(3){
  background: #727df5;
 }
 #kpi_dashboard .averge_score_graph tr:nth-child(4){
  background: #50D2EF;
 }
 #kpi_dashboard .averge_score_graph tr:nth-child(5){
  background: #98D992;
 }
 #kpi_dashboard .averge_score_graph tr:nth-child(6){
  background: #96F4F7;
 }
 #kpi_dashboard .averge_score_graph tr:nth-child(7){
  background: #ECD9E9;
 }
 #kpi_dashboard .averge_score_graph tr:nth-child(8){
  background: #FDCFD7;
 }
 #kpi_dashboard .averge_score_graph tr:nth-child(9){
  background: #D6BAC2;
 }
 #kpi_dashboard .averge_score_graph tr:nth-child(10){
  background: #F6F7B4;
 } */

.average_score_table {
  width: 100%;
  max-height: 285px;
  overflow-y: scroll;
  display: inline-block;
}

.average_score_table::-webkit-scrollbar {
  width: 10px;
}

.average_score_table::-webkit-scrollbar-track {
  background: #ffffff;
}

.average_score_table::-webkit-scrollbar-thumb {
  background: #fc96a5;
}

.average_score_table::-webkit-scrollbar-thumb:hover {
  background: #fc96a5;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #89073c !important;
}

.custom-control-input:active~.custom-control-label::before {
  color: #fff;
  background-color: #89073c !important;
}

.form-group.dolo span {
  position: relative;
  bottom: -50px;
  font-size: 16px !important;
}

#schedule_form #calender .input-group-text {
  position: relative;
  top: 22px;
  height: 100%;
  padding: 22px 10px;
  right: -11px;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #850037 !important;
  border-color: #850037 !important;
  color: #ffffff !important;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 21px;
  font-weight: 600;
  color: #850037;
}

.rbc-toolbar button:focus {
  color: #fff !important;
  background-color: #850037 !important;
  border-color: #850037 !important;
}

#schedule_calender .overflow-auto {
  overflow-x: auto !important;
}

.rbc-agenda-empty {
  font-size: 20px;
  position: relative;
  top: 50%;
  text-align: center;
  color: #151b26 !important;
}

#schedule_form .btn_block .btn {
  max-width: 137px !important;
}

.rbc-agenda-table thead tr th:nth-child(2) {
  display: none;
}

/* .rbc-agenda-content .rbc-agenda-table tbody tr td:nth-child(2){
  display: none;
} */
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell span {
  display: none !important;
}

.rbc-agenda-table .rbc-header {
  text-align: center !important;
}

.schedule_calender .rbc-label.rbc-time-header-gutter {
  display: none;
}

.schedule_calender .rbc-time-slot {
  display: none;
}

.schedule_calender .rbc-time-view {
  border: none !important;
}

.schedule_calender .rbc-time-header.rbc-overflowing {
  height: 25%;
  margin: 0 !important;
}

.schedule_calender .rbc-time-content {
  display: none !important;
}

.schedule_calender .rbc-time-header-content {
  border: 1px solid #ddd !important;
}

.rbc-toolbar {
  margin-bottom: 35px !important;
}

.preview_schedule {
  display: block;
  float: left;
  /* width: 300px;
  height: 300px; */
}

.preview_schedule .h3_title {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #151b26;
  margin-top: 20px;
}

.preview_schedule .custom_title {
  color: #7e7b7b;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: capitalize;
}

.preview_schedule .db_data {
  text-transform: capitalize;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.3px;
  line-height: 17px;
  font-weight: 600;
}

.preview_schedule .custom_article {
  margin-bottom: 20px;
}

.rbc-agenda-time-cell,
.rbc-continues-after {
  display: none;
}

.schedule_title {
  color: #000000 !important;
  border-bottom: 2px solid #000000;
  display: inline-block;
  margin-bottom: 4px;
}

.btn_delete_schedule {
  border: 1px solid #151b26;
  background: #151b26;
  color: #ffffff;
  font-weight: 700;
}

.month_picker .react-datepicker__month-wrapper {
  display: flex !important;
}

.monthly_tab .box_input .custom-radio .custom-control-label::before {
  border-radius: inherit;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td {
  vertical-align: middle !important;
}

.nth_element:nth-child(n) {
  display: none;
}

.nth_element:nth-child(1) {
  display: block !important;
}

.react-add-to-calendar__dropdown a {
  color: black;
}

.react-add-to-calendar {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #aab9d4;
  border-radius: 3px;
  color: #000;
  cursor: pointer;
}

.btn-outline-danger {
  border-color: #850037 !important;
  color: #850037 !important;
}

.btn-outline-danger:hover {
  background-color: #850037 !important;
  color: #ffffff !important;
}

.latestInspectionModal .modal-dialog {
  max-width: 700px;
}


.latestInspectionModal .responsive-table.latest_ins_table li {
  border-radius: 3px;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.latestInspectionModal .responsive-table.latest_ins_table .table-header {
  background-color: #990d46;
  color: #ffffff;
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0.03em;
}

.latestInspectionModal .responsive-table.latest_ins_table .table-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}

.latestInspectionModal .responsive-table.latest_ins_table .col {
  flex-basis: 25%;
  text-align: center;
}

@media all and (max-width: 767px) {
  .latestInspectionModal .responsive-table.latest_ins_table .table-header {
    display: none;
  }

  .latestInspectionModal .responsive-table.latest_ins_table li {
    display: block;
  }

  .latestInspectionModal .responsive-table.latest_ins_table .col {
    flex-basis: 100%;
  }

  .latestInspectionModal .responsive-table.latest_ins_table .col {
    display: flex;
    padding: 10px 0;
  }

  .latestInspectionModal .responsive-table.latest_ins_table .col:before {
    color: #6C7A89;
    padding-right: 10px;
    content: attr(data-label);
    flex-basis: 50%;
    text-align: right;
  }
}

.active_status {
  color: #850037;
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 20px;
}

.feedback_inner .list_items {
  padding: 40px 20px;
  border-bottom: 1px solid #a5a4a4;
}

.feedback_inner .list_items .h5_title {
  font-size: 30px;
  font-weight: 600;
  color: #000000;
  text-transform: capitalize;
}

.maintain_div {
  width: 100%;
  display: block;
  float: left;
}

.thank_msg {
  font-size: 34px;
  line-height: 60px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: capitalize;
  color: #000000;
  margin-top: 20px;
  float: left;
}

.comments_wrap .btn-wait:after,
.btn-wait:before {
  left: 46%;
}

.comments_wrap .btn-wait::before {
  background-image: none;
}

.preview_feedback {
  border: 1px solid #d0cece;
  padding: 20px;
  border-radius: 20px;
}

.time_picker_custom .form-control::before {
  content: ' Check ';
  position: absolute;
}

.custom_row_card {
  border: 1px solid #bcb6b6;
  padding: 20px 0;
  border-radius: 10px;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.time_search_picker .form-group.custom-group {
  margin: 0 !important;
}

.user_selected_deficiencies_btn {
  margin: 10px;
  background-color: #850037;
  color: #fff;
  cursor: default !important;
  border-radius: 20px !important;
}

.FLH_col {
  display: flex;
  justify-content: center;
}

.FLH_box_container {
  text-align: center;
  min-width: 300px;
  max-width: 300px;
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-top: 50px;
  background-color: #e7e7e7;
  border: 1px solid #850037;
  border-radius: 0.6rem;
  box-shadow: 0px 1px 5px 1px #850037;
}

.FLH_button_container {
  text-align: center !important;
  margin-top: 50px;
}

.FLH_button {
  min-width: 350px;
  max-width: 350px;
  min-height: 60px;
  max-height: 60px;
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 0.5rem !important;
  white-space: normal !important;
}

@media (max-width: 370px) {
  .FLH_box_container {
    min-width: 230px;
    max-width: 230px;
    font-size: 16px !important;
  }

  .FLH_button {
    min-width: 250px;
    max-width: 250px;
    font-size: 14px !important;
  }
}

@media (max-width: 270px) {
  .FLH_box_container {
    min-width: 180px;
    max-width: 180px;
    font-size: 14px !important;
  }

  .FLH_button {
    min-width: 200px;
    max-width: 200px;
    font-size: 12px !important;
  }
}

.resolveModal .MuiBackdrop-root {
  background-color: #00000045 !important;
}

.resolveModal .MuiPaper-root {
  width: 50%;
  border-style: double;
  border-width: 5px;
  border-color: #850037;
}

.MuiDialog-paperWidthSm {
  max-width: 100% !important;
  max-height: 80% !important;
}

.resolveModal .MuiPaper-elevation24 {
  box-shadow: 0px 0px 10px 1px #bc004e40, 0px 0px 10px 1px #6b6b6b, 0px 0px 10px 1px #6b6b6b !important;
}

.resolveModal .MuiPaper-rounded {
  border-radius: 30px !important;
  background-color: #f8ffff !important;
}

.rm_wrapper {
  margin-bottom: 4%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
  float: left;
}

.rm_header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.rm_profile {
  width: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.rm_border_bottom {
  margin-bottom: 25px;
  border-color: #bc004e;
  border-bottom-style: ridge;
  border-width: medium;
}

.rm_bgm {
  padding: 1rem !important;
  margin: -20px
}

.rm_contain_box {
  border: medium groove #00000030;
  border-radius: 15px;
  width: 100%;
  padding: 15px;
  background: #ffffff;
}

.rm_showAttachment {
  border-top: medium dashed #00000029;
  padding-top: 10px;
  text-align: right;
  margin-bottom: 5px
}

.rm_imgAllign {
  float: left;
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.rm_IMGiconWrap {
  display: inline-block;
  position: relative;
}

.rm_attachmentIMG {
  width: 100px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.rm_cancel_icon {
  position: absolute;
  top: 5px;
  right: 6px;
  background: #73003a;
  border-radius: 50%;
  width: 19px;
  height: 20px;
  padding: 3px;
}

.rm_resolveButton {
  margin-top: 6% !important;
  margin-bottom: 5% !important;
}

.rm_close {
  position: absolute;
  right: 35px;
  top: 15px;
  font-size: 40px;
  font-weight: bold;
  color: #00000050;
}

.rm_close:hover,
.rm_close:focus {
  color: #bc004e;
  cursor: pointer;
}

@keyframes glow {
  0% {
    box-shadow: 5px 5px 20px rgb(93, 52, 168), -5px -5px 20px rgb(93, 52, 168);
  }

  50% {
    box-shadow: 5px 5px 20px rgb(81, 224, 210), -5px -5px 20px rgb(81, 224, 210)
  }

  100% {
    box-shadow: 5px 5px 20px rgb(93, 52, 168), -5px -5px 20px rgb(93, 52, 168)
  }
}

#glowAnimation {
  animation: glow 1s infinite;
  transition: 0.5s;
  margin: 10px 0;
}

.ptc_container {
  min-width: 100%;
}

.ptc_cover {
  display: flex;
  justify-content: space-between;
}

.ptc_cards {
  display: flex;
  justify-content: space-between;
  width: 35%;
  max-width: 330%;
}

.ptc_buttons {
  cursor: auto !important;
  width: 30%;
  max-width: 300%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebf0f7;
  border-left: 4px solid #850037 !important;
  border-right: 4px double #ffffff;
  border-bottom: 4px double #ffffff !important;
  border-top: 4px double #ffffff !important;
}


.FLUP {
  width: 100%;
  max-width: 100%;
}

.FLUP .padding {
  padding: 0rem !important;
  display: flex;
  justify-content: center;
}

.FLUP .card {
  background-color: #e5e5e5;
  margin-bottom: 30px;
}

.FLUP .overflow {
  overflow: hidden;
}

.FLUP .user-profile {
  padding: 70px 0;
  background: linear-gradient(to right, #30000f, #001533);
}

.FLUP .card-block {
  padding: 1.25rem;
}

.FLUP .image_container {
  margin-bottom: 25px;
}

.FLUP .img-flow {
  border-radius: 140px;
  width: 50%;
  border-style: dashed;
  border-width: 3px;
  border-color: #e5e5e5;
}

.FLUP .f-w-600 {
  color: #dfe3ff;
  font-size: 33px;
  font-weight: bold;
}

.FLUP .m-t-10 {
  color: #ffd5e8;
  font-size: 20px;
}

.FLUP .b-b-default {
  border-bottom: 3px solid #9e9e9e !important;
}

.FLUP .u_title {
  font-weight: 800;
  font-size: 28px;
  font-family: inherit;
  color: #001533;
}

.FLUP .m-b-20 {
  margin-bottom: 35px;
}

.FLUP .m-t-40 {
  margin-top: 20px;
}

.FLUP .p-b-5 {
  padding-bottom: 5px !important;
}

.FLUP .m-b-10 {
  margin-bottom: 10px;
}

.FLUP .key_text {
  color: #000000e8;
  font-size: 20px;
  font-weight: 700;
}

.FLUP .value_text {
  color: #7d0034;
  font-size: 18px;
  font-weight: 600;
}

.createSchedulesButtonDrop {
  right: 10px;
  text-align: center;
  background-color: #ffffff;
}

.createSchedulesButtonDrop a:hover {
  background-color: #ebf0f7;
}

@media (min-width: 575px) and (max-width: 1199px) {
  .createSchedulesButtonDrop {
    left: 1px !important;
    min-width: 210px !important;
  }

  .schedulesButtonWrap {
    display: flex;
    justify-content: space-between;
  }
}

@media(max-width: 574px) {
  .createSchedulesButtonDrop {
    left: 0 !important;
    min-width: 210px !important;
  }

  .schedulesButtonWrap {
    display: flex !important;
    justify-content: space-between !important;
  }
}

.dateInput>.form-group.custom-group {
  width: 315px !important;
}

.dateInput i {
  margin-right: 10px;
}

.deleteTrash {
  position: absolute !important;
  right: 20px !important;
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  width: 20px !important;
}

.scheduleHistoryButtonDrop {
  right: 10px;
  text-align: center;
  background-color: #ffffff;
}

.scheduleHistoryButtonDrop a:hover {
  background-color: #ebf0f7;
}

@media (min-width: 575px) and (max-width: 1199px) {
  .scheduleHistoryButtonDrop {
    right: 10px !important;
    min-width: 210px !important;
  }
}

@media(max-width: 574px) {
  .scheduleHistoryButtonDrop {
    right: 10 !important;
    min-width: 210px !important;
  }
}

.theatre video {
  width: 400px;
  height: 240px;
}

@media(max-width: 760px) {
  .theatre video {
    width: 100% !important;
    height: 150px !important;
  }
}

/* antd_select_button_dropdown */
.ant-select-dropdown {
  z-index: 5000000000 !important;
}

/* QRCODE_SIZE_CHANGING_BUTTON_DROPDOWN_CSS */
.qrCode_sizeSetup_antd_select .ant-select-single .ant-select-selector {
  background-color: #850037 !important;
  border: 1px solid #850037 !important;
  color: #ffffff !important;
  font-size: 0.9375rem !important;
}

.qrCode_sizeSetup_antd_select .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #ffffff !important;
}

/* inventory_access_select_button */
.inventory_antd_select .ant-select-single .ant-select-selector {
  background-color: #fffdea !important;
  border: 1px solid #850037 !important;
  color: #0e0e0e !important;
}

/* inventory_access_select_button_arrow_symbol */
.inventory_antd_select .ant-select-arrow {
  color: #89073c !important;
}

/* inventory_access_display_card */
.inventory_antd_card .ant-card:not(.ant-card-bordered) {
  border-color: #ffdeeb !important;
  box-shadow: 0 1px 2px -2px #85003729, 0 3px 6px 0px #8500375c, 0 5px 12px 4px #85003721 !important;
}

/* inventory_scan_for_delivery_flow_uploadImage_css */
.antdImgUpload .ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
  color: #ffffff00;
  text-decoration: none !important;
  background-color: #ffffff00 !important;
  box-shadow: none !important;
}

.antdImgUpload .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error {
  border: 2px solid #00cc128f !important;
}

.pickingTicketId .ant-descriptions .ant-descriptions-view {
  border: 2px solid #d7d7d7 !important;
}

.pickingTicketId .ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-label {
  color: #000000e0 !important;
  font-weight: bold !important;
  width: 25% !important;
  text-align: center !important;
  /* background: #e9ecef !important; */
}

.pickingTicketId .ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-content {
  color: #555555 !important;
  font-weight: bold !important;
  width: 25% !important;
  text-align: center !important;
}

/* FOR CHI SURVEY FEEDBACK - FOR 4 QUARTERS IN A YEAR */
.swal2-icon.swal2-info {
  border-color: #9e637b !important;
  color: #7b465c !important;
}

/* For Leave Request Form */
.leaveRequest_attaches {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
}

.leaveRequest_attaches-item {
  position: relative;
  width: 90px;
  height: auto;
}

.leaveRequest_attaches img {
  width: 90%;
  border: 1px solid #ccc;
}

.leaveRequest_attaches-delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #7b465c;
  color: white;
  cursor: pointer;
  padding: 2px 5px;
  font-weight: bold;
}

.fw-bold {
  font-weight: 600 !important;
}

.text-warming {
  color: #ffbb00 !important;
}

.start_date_custom_width {
  margin-right: 364px;
}

/* AVAILABILITY CALENDAR INBUILD CSS CHANGES */

/* For hiding the time label in availability calendar */
.availability_calendar .rbc-event-label {
  display: none !important;
}

/* For hiding the current time indicator */
.availability_calendar .rbc-current-time-indicator {
  display: none !important;
}

.availability_calendar .rbc-btn-group {
  width: 245px !important;
}

.availability_calendar .rbc-current-time-indicator {
  height: 2px !important;
}

.availability_calendar .rbc-time-view {
  border: 5px double #896774 !important;
  /* box-shadow: 0px 0px 15px 0px #000000 !important; */
}

/* for removing the First default week row */
.availability_calendar .rbc-allday-cell {
  display: none !important;
}

.availability_calendar .rbc-time-header {
  min-height: 10% !important;
}

.availability_calendar .rbc-time-header-content {
  min-height: 100% !important;
}

.availability_calendar .rbc-time-view .rbc-row {
  min-height: 55px !important;
}

.availability_calendar .rbc-time-header-content {
  border: 0px solid #ddd !important;
}

.availability_calendar .rbc-header {
  display: flex !important;
  justify-content: center !important;
  background-color: #cacaca !important;
  background-image: repeating-linear-gradient(360deg, #ebebeb, #cacaca 10%, #c5c5c5 10%) !important;
  border-bottom: 7px solid #89073c !important;
  border-left: 0px solid #cacaca !important;
  font-size: medium !important;
  color: #414141 !important;
}

.availability_calendar .rbc-time-header-gutter {
  background-color: #cacaca !important;
  background-image: repeating-linear-gradient(360deg, #ebebeb, #cacaca 10%, #c5c5c5 10%) !important;
  border-bottom: 7px solid #89073c !important;
}

.availability_calendar .rbc-today {
  background-color: #ffffff !important;
  background-image: repeating-linear-gradient(360deg, #ffffff, #ebebeb 10%, #ffffff 10%) !important;
  color: #89073c !important;
  /* font-size: larger !important; */
}

.availability_calendar .rbc-events-container {
  margin-right: 0px !important;
}

.availability_calendar .rbc-day-slot {
  background-image: repeating-linear-gradient(190deg, #ececec, #dadada 7%, #ebebeb 10%) !important
}

.availability_calendar .rbc-day-slot .rbc-time-slot {
  border-top: 0px solid #001f36 !important;
}

/* This css functionality for combining the calendar coulmn into a single one without breaking the css colors */
.availability_calendar .rbc-day-slot .rbc-event {
  width: 100% !important;
  left: 0% !important;
}

.availability_calendar .rbc-time-column {
  min-height: 500% !important;
  background-color: #334d6a !important;
  color: #ffffff !important;
}

.availability_calendar .rbc-time-content>*+*>* {
  border-left: 1px solid #fff !important;
}

.availability_calendar .rbc-timeslot-group {
  border-bottom: 1px solid #fff !important;
}

/* Shift Swap Css integrations */
.shiftSwapIcon .anticon {
  color: #303030 !important;
  cursor: pointer !important;
}

.shiftSwapIcon .anticon:hover {
  color: #89073c !important;
}


/* 5 Form css  */

body {
  font-family: "Open Sans", sans-serif;
}

section.teammanage_formmain {
  margin-left: 0px;
  margin-top: 125px;
}

h1.tm_top_head {
  font-size: 24px;
  line-height: 23px;
  letter-spacing: 0.02px;
  font-weight: 600;
  margin-bottom: 0px;
  padding: 30px 0px 26px;
  text-align: center;
}

.tm_form_wrapper {
  border: 1px solid #c7cdd199;
  border-radius: 4px;
  padding: 25px;
  margin-bottom: 20px;
}

.tm_form_wrapper .tm_form_wrap {
  border: 1px solid #c7cdd199;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.tm_form_wrapper .tm_form_wrap .form-group {
  position: relative;
}

.tm_form_wrapper .tm_form_wrap .form-group label {
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.01px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #566975;
  width: 100%;
}

.tm_form_wrapper .tm_form_wrap .form-group .form-control {
  border: 1px solid #c7cdd199;
  height: 45px;
  font-size: 13px;
  line-height: 17px;
  color: #3e4b50;
  font-weight: 600;
  width: 100%;
  border-radius: 3px;
  padding: 11px 10px;
  background: transparent;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.tm_form_wrapper .tm_form_wrap .form-group .form-control:focus {
  box-shadow: none;
}

.tm_form_wrapper .tm_form_wrap .form-group .form-control::placeholder {
  color: #3e4b50 99;
}

.tm_form_wrapper .tm_form_wrap .form-group .form-control:focus-visible {
  outline: none;
}

.tm_form_wrapper .tm_form_wrap .form-group .form-select {
  border: 1px solid #c7cdd199;
  height: 45px;
  font-size: 13px;
  line-height: 17px;
  color: #3e4b5099;
  font-weight: 600;
  width: 100%;
  border-radius: 3px;
  padding: 11px 10px;
  background: transparent;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.tm_form_wrapper .tm_form_wrap .form-group .form-select:focus {
  box-shadow: none;
}

.tm_form_wrapper .tm_form_wrap .form-group .form-select::placeholder {
  color: #3e4b50 99;
}

.tm_form_wrapper .tm_form_wrap .form-group .form-select:focus-visible {
  outline: none;
}

.tm_form_wrapper .tm_form_wrap .form-group textarea.form-control {
  resize: none;
  height: 100px;
}

.action_button {
  text-align: right;
  cursor: pointer;
}

.action_button span {
  color: #7a002c;
  font-weight: bold;
  display: inline-block;
  padding: 0px 0px 10px;
  font-size: 14px;
  line-height: 19px;
}

.action_button a {
  color: #7a002c;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 600;
}

.theme_def_btn1 {
  background-color: #850037;
  border: 1px solid #850037;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  padding: 9.5px 18px;
  min-width: 119px;
  font-weight: 600;
}

.theme_def_btn1:hover {
  color: #fff;
}

.theme_def_btn2 {
  background-color: #850037;
  border: 1px solid #850037;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  padding: 9.5px 18px;
  min-width: 119px;
  font-weight: 600;
  background-color: #151c26;
  border: 1px solid #151c26;
}

.theme_def_btn2:hover {
  color: #fff;
}

.submitcancel_btn {
  text-align: center;
  margin: 18px 0 12px;
}

.submitcancel_btn button {
  margin: 0 10px;
}

.label_tick {
  position: relative;
  top: -2px;
  left: 5px;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.dropdown_caret {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 0;
}

.acr__inner {
  float: left;
  width: 100%;
  margin: 0px 0 20px 50px;
  border-left: solid 1px #45a2c1;
  padding-left: 20px;
}

.team_checklist_checkbox_card_wrapper {
  border: 1px solid #c7cdd199;
  padding: 20px;
  border-radius: 4px;
  margin: 30px 0px 40px;
}

.team_management_card_wrap {
  border: 1px solid #c7cdd199;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #fcfcfc;
}

.team_management_card_wrap .card_top_wrap {
  background-color: #7a002c;
  border: 1px solid #7a002c;
  padding: 12px 20px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team_management_card_wrap .card_top_wrap h4 {
  color: #fff;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

.team_management_card_wrap .img_chcekbox_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;
  width: 100%;
  gap: 20px;
  min-height: 190px;
  max-height: 190px;
}

.team_management_card_wrap .img_chcekbox_wrapper .left_img_wrap {
  width: 120px;
}

.team_management_card_wrap .img_chcekbox_wrapper .right_checkbox_wrap {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.team_management_card_wrap .img_chcekbox_wrapper .custom_checkbox_wrap {
  padding-top: 9px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: stretch;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  align-content: stretch;
}

.cstm_check_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  font-weight: 600;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
}

.cstm_check_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.cstm_check_container input:checked~.checkmark {
  background-color: #566975;
}

.cstm_check_container input:checked~.checkmark:after {
  display: block;
}

.cstm_check_container .checkmark:after {
  left: 10px;
  top: 4px;
  width: 10px;
  height: 17px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark {
  position: absolute;
  top: -2px;
  left: 0px;
  height: 30px;
  width: 30px;
  border-radius: 2px;
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.cstm_checkbox_wrap_new {
  margin-bottom: 13px;
  width: 50%;
}

.cstm_checkbox_wrap_new .checkmark {
  height: 15px;
  width: 15px;
  border: 1px solid #626262;
}

.cstm_checkbox_wrap_new .checkmark::after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
}

.cstm_checkbox_wrap_new .cstm_check_container {
  padding-left: 23px;
  font-size: 13px;
  line-height: 11px;
  color: #566975;
  font-weight: 600;
}

.input-trasparent {
  border: 0;
  resize: none;
  background-color: transparent;
  outline: 0;
  border-radius: 0;
}

.input-trasparent:focus {
  box-shadow: none;
}

.scrore-card {
  border: 1px solid #c7cdd199;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 5px;
  gap: 15px;
  min-height: 207px;
  justify-content: center;
}

.scrore-card .scrore-input-card {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.scrore-card .scrore-input-card label {
  display: inline-block;
  font-size: 15px;
  color: #151b26;
  font-weight: 600;
  flex-shrink: 0 !important;
}

.scrore-card .scrore-input-card .input-border-bottom {
  flex-grow: 1 !important;
}

.scrore-card .scrore-input-card.scrore-input-card-center {
  max-width: 137px;
}

.input-border-bottom {
  border: 0 !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  height: 20px !important;
  padding: 5px 0 !important;
}

.input-border-bottom:focus {
  box-shadow: none;
  outline: 0;
  background-color: transparent;
  border-bottom-color: #000;
}

.cstm_checkbox_wrap_new.cstm_checkbox_wrap_new_inline_sm {
  display: flex;
  align-items: center;
}

.cstm_checkbox_wrap_new.cstm_checkbox_wrap_new_inline_sm .left_img_wrap.flex-shrink-0 {
  width: 60px;
}

.cstm_checkbox_wrap_new.cstm_checkbox_wrap_new_inline_sm .checkmark {
  top: -6px;
}

.digit_box_wrap {
  display: flex;
  align-items: center;
}

.digit_box_wrap .digit_wrap {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-left: 7px;
}

.form-group .css-26l3qy-menu {
  background: #fff !important;
  background-color: #fff !important;
  z-index: 9;
}

/* 15 dec new css */
.mid_title {
  color: #101010;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 15px;
  font-weight: 600;
  letter-spacing: 0.02px;
}

.custom_checkbox .form-group {
  display: block;
  margin-bottom: 15px;
}

.custom_checkbox .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom_checkbox .form-group label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.custom_checkbox .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #767676;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  padding: 0;
  width: 15px;
  height: 15px;
  top: -2px;
}

.custom_checkbox .form-group input:checked+label:after {
  content: "✓";
  display: block;
  position: absolute;
  background-color: #840136;
  width: 15px;
  height: 15px;
  top: 3px;
  color: #fff;
  line-height: 15px;
  padding: 0 3px;
}


.selectdays_numb {
  display: flex;
  align-items: baseline;
}

.mixed_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mixed_label label {
  text-align: center;
}

.daysnumb_input {
  display: inline-block;
  padding-left: 15px;
  flex: 33.33%;
  max-width: 33.33%;
}

.inputadded_numb {
  display: flex;
  align-items: center;
  border: 1px solid #c7cdd199;
  margin-bottom: 5px;
}

.inputadded_numb input {
  border: 0 !important;
  text-align: center;
}

.inputadded_numb.active {
  background-color: #7a002c0d;
  border: 1px solid #7a002c;
}

.inputadded_numb.active input {
  color: #000 !important;
}

.inputadded_numb:nth-child(odd) {
  background: #f4f6fb;
  border: 1px solid #3e4b5039;
}

.totaldays_numb,
.addmore_days {
  display: flex;
  align-items: center;
}

.addmore_days {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding-right: 15px;
}

.totaldays_numb label {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding-right: 15px;
}

.totaldays_numb span {
  padding: 0 0px 0px 15px;
  width: 33.33%;
}

.totaldays_numb input {
  float: right;
}

.monthproduct_repblock {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.monthproduct_repblock .form-group {
  width: 100%;
}

.border-btm-1 {
  border-bottom: 1px solid #c7cdd199;
}

.totalmontrep_numb {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.tm_form_head h3 {
  font-size: 13px;
  line-height: 18px;
  color: #566975;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 15px;
}

/* 22 dec 2023 start */
.percentile_img {
  position: absolute;
  top: 41px;
  right: 15px;
  z-index: 1;
}

.areas_field_wrap .form-group label {
  margin-bottom: 0px;
}

.areas_field_wrap .form-group input {
  color: #3e4b50 !important;
}

.areas_field_wrap .form-group input::placeholder {
  color: #3e4b50 !important;
}

.areas_field_wrap .submitcancel_btn {
  margin: 40px 0 12px;
}

.increase_field button {
  vertical-align: baseline;
}

.total_comments .form-group {
  display: flex;
  align-items: center;
  height: 45px;
  margin-bottom: 1rem;
  gap: 15px;
}

.total_comments .form-group label {
  margin-bottom: 0px !important;
}

label.form-label.border_label {
  border: 1px solid #c7cdd199;
  border-radius: 4px;
  height: 45px;
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000 !important;
  font-size: 14px !important;
  letter-spacing: 0.3px !important;
}

.increase_field.row_field {
  text-align: right;
  margin-right: 15px;
}

.btn:focus,
.btn.focus {
  outline: 0px;
  box-shadow: none;
}

/* Modal Css Start */
.custom_modal .modal-dialog,
.custom_modal_jobcard .modal-dialog {
  max-width: 405px;
  margin: 88px auto;
}

.custom_modal .modal-header,
.custom_modal_jobcard .modal-header {
  background-color: #7a002c;
  color: #fff;
  padding: 12px 20px;
  align-items: center;
}

.custom_modal .modal-header h5,
.custom_modal_jobcard .modal-header h5 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}

.custom_modal .modal-body,
.custom_modal_jobcard .modal-body {
  padding: 1rem 1rem 0px;
}

.custom_modal .modal-body .form-group,
.custom_modal_jobcard .modal-body .form-group {
  position: relative;
}

.custom_modal .modal-body .form-group label,
.custom_modal_jobcard .modal-body .form-group label {
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.01px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #566975;
  width: 100%;
}

.custom_modal .modal-body .form-group .form-select,
.custom_modal_jobcard .modal-body .form-group .form-select {
  border: 1px solid #c7cdd199;
  height: 45px;
  font-size: 13px;
  line-height: 17px;
  color: #3e4b50;
  font-weight: 600;
  width: 100%;
  border-radius: 3px;
  padding: 11px 10px;
  background: transparent;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.custom_modal .modal-body .form-group .form-select:focus,
.custom_modal_jobcard .modal-body .form-group .form-select:focus {
  outline: none;
}

.custom_modal .modal-body .form-group .form-control,
.custom_modal_jobcard .modal-body .form-group .form-control {
  border: 1px solid #c7cdd199;
  height: 45px;
  font-size: 13px;
  line-height: 17px;
  color: #3e4b50 99;
  font-weight: 600;
  width: 100%;
  border-radius: 3px;
  padding: 11px 10px;
  background: transparent;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.custom_modal .modal-body .form-group .form-control:focus,
.custom_modal_jobcard .modal-body .form-group .form-control:focus {
  box-shadow: none;
}

.custom_modal .modal-body .form-group textarea.form-control,
.custom_modal_jobcard .modal-body .form-group textarea.form-control {
  height: 60px;
  min-height: 60px;
  resize: none;
}

.custom_modal .modal-body .form-group textarea.form-control:focus,
.custom_modal_jobcard .modal-body .form-group textarea.form-control:focus {
  box-shadow: none;
}

.custom_modal .modal-footer,
.custom_modal_jobcard .modal-footer {
  padding: 25px 0px 20px;
  border: 0px;
}

.custom_modal .modal-footer button,
.custom_modal_jobcard .modal-footer button {
  margin: 0 auto;
}

.custom_modal_jobcard .modal-dialog {
  max-width: 550px;
}

.custom_modal_jobcard .modal-header .form-group {
  width: calc(100% - 34px);
}

.custom_modal_jobcard .modal-header .form-control {
  border: 1px solid #c7cdd199;
  background-color: #fff;
  height: 45px;
  font-size: 16px;
  line-height: 22px;
  color: #3e4b50 99;
  font-weight: 400;
  width: 100%;
  border-radius: 3px;
  padding: 11px 10px;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.custom_modal_jobcard .modal-header .form-control:focus {
  box-shadow: none;
}

/* Modal Css End */
.setup_box_btn .form-group {
  border: 1px solid #c7cdd199;
  border-radius: 3px;
  padding: 15px;
  display: flex;
  gap: 8px;
  justify-content: center;
}

/* 22 dec 2023 end */
/* 27 dec 2023 start */
.scrore-input-card p {
  min-width: 120px;
  border-bottom: 1px solid;
  margin-bottom: 0;
  text-align: center;
  line-height: 20px;
}

.adddetail_input .submitcancel_btn {
  margin-top: 0px;
  margin-bottom: 0px;
}

.adddetail_input .submitcancel_btn button {
  height: 45px;
}

.adddetail_input {
  display: flex;
  align-items: center;
}

/* 27 dec 2023 end */


/* Media */

@media (max-width: 1366px) {

  .custom_modal .modal-dialog,
  .custom_modal_jobcard .modal-dialog {
    margin: 20px auto;
  }
}


@media (max-width: 991px) {
  .team_management_card_wrap .img_chcekbox_wrapper {
    max-height: unset;
  }

  .addmore_days,
  .daysnumb_input {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .selectdays_numb .action_button {
    margin-left: 2px;
  }

  .totaldays_numb label {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .totaldays_numb span {
    padding: 0 0px 0px 15px;
    width: 50%;
  }

  .totaldays_numb span input {
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .team_management_card_wrap .img_chcekbox_wrapper {
    flex-direction: column;
  }

  .team_management_card_wrap .img_chcekbox_wrapper .right_checkbox_wrap {
    flex-wrap: wrap;
  }

  .team_management_card_wrap .img_chcekbox_wrapper .custom_checkbox_wrap {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .selectdays_numb {
    flex-flow: column;
  }

  .addmore_days,
  .daysnumb_input {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
  }

  .addmore_days {
    margin-bottom: 25px;
  }

  .totaldays_numb {
    flex-flow: column;
  }

  .totaldays_numb label {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .totaldays_numb span {
    padding: 0;
    width: 100%;
  }

  .adddetail_input {
    flex-flow: column;
  }

  .adddetail_input .submitcancel_btn {
    margin-top: 20px;
  }
}


/****** Monthly report ******/

.monthlyuse_datepicker_wrap .form-group label {
  font-size: 13px;
  line-height: 23px;
  letter-spacing: 0.01px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #566975;
  width: 100%;
}

.monthlyuse_datepicker_wrap .form-group .form-select {
  border: 1px solid #c7cdd199;
  height: 45px;
  font-size: 13px;
  line-height: 17px;
  color: #3e4b5099;
  font-weight: 600;
  width: 100%;
  border-radius: 3px;
  padding: 11px 10px;
  background: transparent;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.monthlyuse_datepicker_wrap .form-group .form-control {
  border: 1px solid #c7cdd199;
  height: 45px;
  font-size: 13px;
  line-height: 17px;
  color: #3e4b5099;
  font-weight: 600;
  width: 100%;
  border-radius: 3px;
  padding: 11px 10px;
  background: transparent;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.monthlyuse_datepicker_wrap .form-group {
  position: relative;
}

.monthlyuse_datepicker_wrap .form-group .form-control:focus {
  box-shadow: none;
}

.monthlyuse_datepicker {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}

.monthlyuse_datepicker .form-group {
  width: 111px;
  margin-bottom: 0px;
}

.monthlyuse_datepicker_wrap h1.tm_top_head {
  font-size: 24px;
  line-height: 33px;
  padding: 20px 0px;
  text-align: left;
}

.selectrows {
  position: relative;
}

.paginate_portion {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paginate_portion p {
  color: #566975;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}

.paginate_portion p span.active {
  color: #7a002c;
}

.monthlyuse_datepicker_wrap .paginate_portion .form-select {
  border-color: #707070 !important;
  line-height: 13px !important;
  height: 31px;
  padding: 0px 10px;
  color: #2e2e2e;
  min-width: 86px;
}

.monthlyuse_datepicker_wrap .paginate_portion .dropdown_caret {
  bottom: 3px;
}

.monthlyuse_datepicker_wrap .paginate_portion .form-select:focus-visible,
.monthlyuse_datepicker_wrap .form-group .form-select:focus-visible {
  box-shadow: none !important;
  outline: none;
}

.nxtprv_btn {
  border: 1px solid #abb5bb;
  font-size: 14px;
  line-height: 18px;
  color: #5669757e;
  font-weight: 600;
  border-radius: 2px;
  min-width: 80px;
}

.pagselect_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nextprev_btn {
  display: flex;
}

.nxtprv_btn.active {
  border-color: #566975;
  color: #566975;
}

.summary_input_wrapper {
  margin: 30px 0px;
  padding: 0 15px;
  width: 100%;
  overflow: auto;
}

.summary_input_wrapper h2 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  text-align: center;
  display: block;
  background-color: #ebf0f7;
  padding: 11.5px 0;
  margin-bottom: 0px;
}

.summary_input_wrapper table {
  width: 100%;
}

.summary_input_wrapper tr:nth-child(odd) {
  background-color: #ebf0f7;
}

.summary_mu_field {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.summary_input_wrapper td {
  padding: 10px 20px;
}

.summary_mu_field p {
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01px;
  min-width: 150px;
  max-width: 150px;
}

.summary_mu_field span {
  font-weight: 600;
}

.summary_mu_field input {
  border: 1px solid #c7cdd199;
  width: 130px;
  height: 30px;
  font-size: 13px;
  line-height: 18px;
  color: #3e4b5099;
  font-weight: 600;
  border-radius: 3px;
  padding: 0 5px 0px 15px;
}

.summary_mu_field input::placeholder {
  font-weight: 600;
  color: #3e4b5099;
}

.summary_mu_field input:focus {
  outline: none;
}

.addsymbol_field {
  position: relative;
}

.sym_icon {
  position: absolute;
  left: 5px;
  top: 6px;
  font-size: 13px;
  line-height: 18px;
  color: #3e4b5099;
}

/* Modal css */

.monthlyusage_modal .modal-dialog {
  max-width: 338px;
}

.monthlyusage_modal .modal-header {
  background-color: #7a002c;
  padding: 12px 20px;
  justify-content: flex-end;
}

.monthlyusage_modal .modal-body {
  padding: 20px;
}

.monthlyusage_modal .mu_product_modalname p {
  color: #566975;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

.monthlyusage_modal .modal-footer {
  padding: 15px 0px 30px;
  border: 0;
  text-align: center;
  justify-content: center;
}

.monthlyusage_modal .modal-content {
  border: 0;
}

/*********************** Montly Cost Summary And Analysis ***********************/

.monthlyuse_datepicker.organselect .form-group:first-child {
  width: 320px;
}

.monthlyuse_datepicker.organselect .form-group:first-child .dropdown_caret {
  border-left: 1px solid #c7cdd199;
  padding-left: 10px;
  bottom: 12px;
}

.det_title {
  font-size: 14px;
  background-color: #7a002c;
  color: #fff;
  padding: 12px 15px;
  text-align: center;
  margin-bottom: 0px;
}

.monthcost_analys_tables {
  display: flex;
  flex-flow: wrap;
  margin: 10px 5px;
}

.monthdet_field {
  padding: 10.5px 15px;
  border: 1px solid #3e4b5039;
  display: flex;
  align-items: center;
  justify-content: center;
}

.monthdet_field p {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  margin: 0px 0px;
  color: #000000;
}

.monthcost_analys_tables table {
  width: 50%;
  border-spacing: 5px 6px;
  border-collapse: separate;
  margin-bottom: 15px;
}

.monthcost_analys_tables table tr:nth-child(odd) {
  background-color: #f4f6fb;
}

/* Media */

@media (max-width: 575px) {
  .monthlyuse_datepicker_wrap h1.tm_top_head {
    font-size: 19px;
    line-height: 22px;
  }

  .paginate_portion {
    align-items: flex-start;
    flex-flow: column;
  }

  .pagselect_wrap {
    margin-bottom: 15px;
  }

  .nextprev_btn {
    gap: 10px;
  }

  .summary_mu_field p {
    font-size: 13px;
  }
}

/***************** Self Audit ************************/

.selfaudit_title h2 {
  color: #566975;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 15px;
}

.selfaudit_checkpoint p {
  color: #566975;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0px;
  font-weight: 600;
  max-width: 65%;
}

.selfaudit_checkboxwrap {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.selfaudit_checkboxwrap .custom_checkbox .form-group {
  margin-bottom: 0px;
}

.selfaudit_checkpoint {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selfaudit_checkboxwrap .custom_checkbox .form-group input:checked+label:after {
  width: 19px;
  height: 19px;
  padding: 2px 4px;
  border-radius: 3px;
  top: 0px;
}

.selfaudit_checkboxwrap .custom_checkbox .form-group label:before {
  margin-right: 10px;
  width: 19px;
  height: 19px;
  border-radius: 3px;
  top: -3px;
}

/* Media */

@media (max-width: 767px) {
  .selfaudit_checkpoint {
    align-items: flex-start;
    flex-flow: column;
  }

  .selfaudit_checkpoint p {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

/************************* Performance Audit *************************/

.performaudit_steps .tm_form_wrapper {
  padding: 0;
  border: 0;
}

/* Process Steps */

.msform {
  text-align: center;
  position: relative;
  margin-top: 30px;
}

.msform .action-button {
  width: 100px;
  background: #2098ce;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

.msform .action-button-previous {
  width: 100px;
  background: #acbed0;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

.msform .action-button-previous:hover,
.msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #acbed0;
}

.msform #progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  padding: 0px;
}

.msform #progressbar li {
  list-style-type: none;
  color: #0e1828;
  width: 9.09%;
  float: left;
  position: relative;
  letter-spacing: 1px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
}

.msform #progressbar li:before {
  content: "";
  width: 24px;
  height: 24px;
  line-height: 22px;
  display: block;
  font-size: 12px;
  color: #333;
  background: white;
  border-radius: 25px;
  margin: 0 auto 10px auto;
  border: 1px solid #9ea5a7;
  z-index: 2;
  position: relative;
}

.msform #progressbar li.active:before {
  content: "✓";
}

.msform #progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 11px;
  z-index: 1;
  border: 1px solid #9ea5a7;
}

.msform #progressbar li:first-child:after {
  content: none;
}

.msform #progressbar li.active:before,
.msform #progressbar li.active:after {
  color: #fff;
  background: #6f1030;
  border: 1px solid #6f1030;
  font-weight: 600;
}

.custom_notesbox .form-group input {
  color: #566975;
  height: 22px;
  font-size: 12px;
  min-width: 143px;
  border: 1px solid #7676764d;
  border-radius: 3px;
  padding: 2px 6px;
  font-weight: 600;
  position: relative;
  top: -2px;
}

.custom_notesbox .form-group input:focus,
.custom_notesbox .form-group input:focus-visible {
  outline: none;
}

.nextprev_pabtn {
  display: flex;
  justify-content: space-between;
  margin: 25px 0px;
}

.nextprev_pabtn button span {
  padding: 0 8px;
  position: relative;
  top: -1px;
}

.nextprev_pabtn button {
  min-width: auto;
}

/*** Developer CSS ******/

.react-datepicker-popper {
  z-index: 9;
}

.css-tlfecz-indicatorContainer {
  display: none;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.react-datepicker-popper {
  z-index: 9;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #850037 !important;
  color: #fff;
  font-weight: bolder;
}


.rmdp-ym .rmdp-day span:hover {
  background-color: #8500375b !important;
}

.css-1n7v3ny-option {
  background-color: #8500375b !important;
}

.css-1n7v3ny-option:hover {
  background-color: #8500375b !important;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;

}

.css-9gakcf-option {
  background-color: #850037 !important;
}

.rdt {
  width: 100%;
}

.team_management_card_wrap .img_chcekbox_wrapper .custom_checkbox_wrap {
  padding-top: 9px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: stretch;
  gap: 0px 10px;
}

.cstm_check_container input:checked~.checkmark {
  background-color: #7a002c;
}


.form-group .css-26l3qy-menu {
  background: #fff !important;
  background-color: #fff !important;
  z-index: 9;
}

.calendar_icon {
  position: absolute;
  width: 40px;
  background-color: #e9ecef;
  height: 44px;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 6px 0;
  cursor: pointer;
}

.rmdp-day.rmdp-disabled {
  color: #8798ad !important;
}

.digit_box_wrap .digit_box {
  width: 30px;
  height: 30px;
  border: 1px solid #7a002c;
  border-radius: 2px;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #7a002c;
}

.sized-core {
  height: 112px !important;
  word-wrap: break-word;
  white-space: normal;
  width: 156px !important;
  /* margin-top: 32px; */
}

.css-1uccc91-singleValue {
  font-size: 13px;
  font-weight: 600;
  color: #3e4b50;
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

.only.rmdp-month-picker {
  height: 240px;
  position: static;
  width: 300px !important;
}

.place_adjust::placeholder {
  font-size: 11.5px;
  /* Adjust the font size as needed */
  /* color: red;      */
}

.checkbox-group {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  width: 30%;
  /* Adjust the width as needed */

}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

}

.checkbox-item input {
  margin-right: 8px;
  background-color: red;
}

.mg-t {
  margin-top: 1.8rem;
}

.mg-tt {
  margin-top: 8px;
}


.removed-trash {
  background-color: transparent !important;
  width: 2.22rem;
  border: none !important;
}

.mtop-job-card {
  margin-top: 32px;
}

.mtop-time {
  margin-top: .5rem;
}

.mtop-notes {
  margin-top: 1.9rem;
}

.sized-core-utility {
  height: 112px !important;
  word-wrap: break-word;
  white-space: normal;
  /* width: 156px !important; */
}

/* Clock In - Clock Out Prime Timer CSS */
.clkIn_proceedTimer.ant-btn-lg {
  font-size: 20px !important;
  color: #97003f !important;
}

.clkIn_proceedTimer.ant-btn-lg:hover {
  color: #a70046b6 !important;
}

.clkIn_startTimer {
  font-size: xx-large !important;
  font-weight: bolder !important;
  min-height: 70px !important;
}

.moveOnToNextJob {
  font-size: large !important;
  font-weight: bolder !important;
  min-height: 50px !important;
}

.clkIn_timerset1 {
  font-size: 70px !important;
  width: 20rem !important;
  color: #eeeeee !important;
  background-color: #850037 !important;
  border-radius: 20px !important;
}

.clkIn_mealbreak_triggerButton {
  border-style: none !important;
  border-color: white !important;
  background-color: white !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.clkIn_mealbreak_counterText {
  color: #000000;
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-size: 0.8rem;
  line-height: 150%;
  letter-spacing: 2px;
}

.clkIn_mealbreak_timerText {
  color: #585858;
  font-family: 'Lato', sans-serif;
  text-align: center;
  font-size: 0.7rem;
  letter-spacing: 1px;
  margin-top: -10px;
}

/* SHIFT ATTENDANCE DASHBOARD CALENDAR INBUILD CSS CHANGES */

.attendance_dashboard_calendar .rbc-btn-group {
  width: 245px !important;
}

.attendance_dashboard_calendar .rbc-toolbar-label {
  font-size: xx-large !important;
}

.attendance_dashboard_calendar .rbc-month-view {
  border: 5px ridge #e6e6e6 !important;
  /* box-shadow: 0px 0px 15px 0px #000000 !important; */
}

.attendance_dashboard_calendar .rbc-month-header {
  height: 10% !important;
}

.attendance_dashboard_calendar .rbc-header {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-bottom: 7px solid #89073c !important;
  border-left: 0px solid #cacaca !important;
  color: #3f3f3f !important;
  background-color: #cacaca !important;
  background-image: repeating-linear-gradient(360deg, #ebebeb, #cacaca 10%, #c5c5c5 10%) !important;
  font-size: 19px !important;
  font-weight: 500 !important;
  font-family: sans-serif !important;
}

.attendance_dashboard_calendar .rbc-today {
  background-color: #3f3f3f1e !important;
}

.attendance_dashboard_calendar .rbc-now .rbc-button-link {
  background-color: #05306267 !important;
  color: white !important
}

.attendance_dashboard_calendar .rbc-button-link {
  width: 40px !important;
  border-radius: 5px !important;
  margin-top: 5px !important;
  margin-right: 0px !important;
  margin-bottom: 15px !important;
  color: #470721 !important;
  background-color: #e6e6e6 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.attendance_dashboard_calendar .sticher-box {
  height: 30px !important;
  width: 30px !important;
  border-style: ridge !important;
  border-radius: 5px !important;
  border-width: 2px !important;
}

.attendance_dashboard_calendar .presentColor {
  background-color: #77dd77 !important;
  border-color: #68c168 !important;
}

.attendance_dashboard_calendar .absentColor {
  background-color: #FF6961 !important;
  border-color: #e86059 !important;
}

.attendance_dashboard_calendar .complaintColor {
  background-color: #fce50b !important;
  border-color: #e4cf10 !important;
}

/* SHIFT ATTENDANCE DASHBOARD CALENDAR VIEW MODAL INBUILD CSS CHANGES */

.view_attendance_dashboard_calendar_modal {
  max-height: 920px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.view_attendance_dashboard_calendar_modal::-webkit-scrollbar {
  width: 5px;
}

.view_attendance_dashboard_calendar_modal::-webkit-scrollbar-track {
  background: #f4f6fb;
}

.view_attendance_dashboard_calendar_modal::-webkit-scrollbar-thumb {
  background: #850037;
  border-radius: 20px;
}

.view_attendance_dashboard_calendar_modal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* PEGASSURE PLAYBOOK FLOW CSS CHANGES  */

.playBookPdfBox .ant-card-body {
  background-color: #c6c0c038 !important;
}

.playBookPdfBox .ant-card-meta-avatar {
  width: 30% !important;
  max-width: 30% !important;
  display: flex !important;
  justify-content: center !important;
}

.playBookPdfBox .ant-card-meta-detail {
  width: 70% !important;
  max-width: 70% !important;
}

.playBookPdfBox .anticon-file-pdf svg {
  width: 100% !important;
  height: 100% !important;
}

.playBookPdfBox .ant-card-bordered {
  border: 1px solid #ced4da !important;
}

.playBookPdfBox .ant-card-actions {
  border-top: 1px solid #ced4da !important;
}

.playBookPdfBox .ant-card-actions>li:not(:last-child) {
  border-inline-end: 1px solid #ced4da !important;
}

/* play_book_area_list_container */
.pbalc .form-group .checkbox_cst_grid {
  column-gap: 35px !important;
}

.pbalc .ant-card .ant-card-head {
  font-size: 20px !important;
  color: #424242 !important;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;;
  border-bottom: 2px solid #d3d3d3 !important;
}

.pbalc .ant-card .ant-card-body {
  padding: 10px !important;
  border-bottom: 2px groove #a4a4a4 !important;
}

.pbalc .ant-ribbon {
  color: #919191 !important;
  background-color: #850037 !important;
}

.pbalc .ant-card-bordered {
  border: 2px solid #d3d3d3 !important;
}

.pbalc .card_bodyContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
}

.pbalc .card_bodyWard {
  text-align: center !important;
  width: calc(33.33% - 20px) !important;
  margin: 10px !important;
  padding: 20px !important;
  background-color: #e8e8e8 !important;
  border: 2px solid #dadada !important;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .pbalc .card_bodyWard {
    width: 100% !important;
  }

  .pbalc .ant-card-bordered {
    margin-bottom: 20px !important;
  }
}
/* Antd for jobs list in clockin timer screen */
:where(.css-6j9yrn).ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  display: inline-block;
  width: 50%;

}




.brmSchedule_DatePicker{
  display: block;
  width: 12.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.new_inbox_container{
  display:flex;
  flex-direction: row;
  justify-content:space-evenly;

  /* position: relative;
  top:'10rem'; */
}
.Dashboard_newInbox{
  width: 216px;
  /* height:567px; */
}
.Dashboard_newInbox > p {
  width: 168px;
  height: 70px;
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 14px/20px Open Sans;
  letter-spacing: 0px;
  color: #3E4B50;
  opacity: 1;
}
/* [@id="profile"]/form/div[1]/div/div[2]/div/div[2]/div */
body > div:first-child > div > div:nth-child(2) > main > section > div > div:nth-child(2) > div:first-child > div > div:nth-child(2) > div > div > form > div:first-child > div > div:nth-child(2) > div > div:nth-child(2) > div > div:first-child
 {
  max-height: 200px; 
  overflow-y: auto;
  background-color: red;
}

/* custom-radio.css */
.custom-radio .form-check-input:checked {
  background-color: red;
  border-color: red;
}
.custom-radio .form-check-input:focus {
  box-shadow: none;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #850037;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
  
.container-brm {
  display: block;
  position: relative;
  padding-left: 25px; /* Adjust padding if needed */
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px; /* You can adjust the font-size if needed */
  user-select: none;
  margin-right: 15px;
}

.custom-control-input {
  opacity: 0;
  position: absolute;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px; /* Reduced height for smaller checkbox */
  width: 20px; /* Reduced width for smaller checkbox */
  background-color: #eee;
  border-radius: 50%; /* Keep it as a circle */
}

.container:hover .custom-control-input ~ .checkmark {
  background-color: #ccc;
}

.custom-control-input:checked ~ .checkmark {
  background-color: #850037;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 5px; /* Adjusted to center the dot in the smaller checkbox */
  left: 5px; /* Adjusted to center the dot in the smaller checkbox */
  width: 10px; /* Smaller dot for smaller checkbox */
  height: 10px; /* Smaller dot for smaller checkbox */
  background: white;
  border-radius: 50%; /* Keeps the dot circular */
}

.custom-control-input:checked ~ .checkmark:after {
  display: block; /* Show the white circle when checked */
}

.rdtCounter .rdtCount{
  font-size: 20px;
}
.dashboard_admin_brm{
  width: 1066px;
}
/* Base style */

/* Media query for devices with width less than 1804px */
@media (max-width: 1804px) {
  .dashboard_admin_brm {
    width: 956px; /* Adjust width for screens smaller than 1804px */
  }
}

@media (max-width: 1760px) {
  .dashboard_admin_brm {
    width: 800px; /* Adjust width for screens smaller than 1500px */
  }
}

/* Media query for devices with width less than 1500px */
@media (max-width: 1500px) {
  .dashboard_admin_brm {
    width: 756px; /* Adjust width for screens smaller than 1500px */
  }
}

@media (max-width: 1490px) {
  .dashboard_admin_brm {
    width: 700px; /* Adjust width for screens smaller than 1500px */
  }
}

/* Media query for devices with width less than 1200px */
@media (max-width: 1430px) {
  .dashboard_admin_brm {
    width: 540px; /* Adjust width for screens smaller than 1200px */
  }
}

/* Media query for devices with width less than 900px */
@media (max-width: 1272px) {
  .dashboard_admin_brm {
    width: 450px; /* Adjust width for screens smaller than 900px */
  }
}

/* Media query for devices with width less than 600px */
@media (max-width: 1196px) {
  .dashboard_admin_brm {
    width: 50%; /* Adjust width for screens smaller than 600px */
  }
}

/* Media query for devices with width less than 400px */
@media (max-width: 984px) {
  .dashboard_admin_brm {
    width: 730px; /* Adjust width for screens smaller than 400px */
  }
}

/* Media query for devices with width less than 300px */
@media (max-width: 300px) {
  .dashboard_admin_brm {
    width: 30%; /* Adjust width for screens smaller than 300px */
  }
}

/* Media query for devices with width less than 200px */
@media (max-width: 200px) {
  .dashboard_admin_brm {
    width: 100%; /* Adjust width for screens smaller than 200px */
  }
}



.ticket_inbox_over_styling {
  position: absolute;
  left: -16rem;
  border: 1px solid #c7cdd1;
  border-radius: 5px;
  overflow: hidden;
  overflow-x: hidden; /* Hides horizontal scrollbar */
  overflow-y: auto; /* Enables vertical scrollbar */
}
.ticket_inbox_over_styling {
    overflow-y: auto; /* Enables vertical scrolling */
    /* Add other necessary styles if not already defined elsewhere */
}

.ticket_inbox_over_styling:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); /* Adds shadow on hover */
}

/* Styling the vertical scrollbar for Webkit browsers */
.ticket_inbox_over_styling::-webkit-scrollbar {
    width: 15px; /* Width of the vertical scrollbar */
}

.ticket_inbox_over_styling::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.ticket_inbox_over_styling::-webkit-scrollbar-thumb {
    background: #888;
}

.ticket_inbox_over_styling::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


.bold-first-letter {
  font-weight: bold;  
  font-size: 16px;    
  color: #333;        
}

.card-brm {
  background-color: #ffffff;
  padding: 5px 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 195px;
  margin-bottom:10px;
}

.date-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  margin-right: 2.5px;
}

.date {
  color: #333;
}

.hideSafetyField {
  transition: opacity 0.3s ease-in-out, max-height 0.6s ease-in-out;
  opacity: 0;
  max-height: 0;
  overflow: visible;
  pointer-events: none; /* Prevent interaction */
  position: relative; /* Ensure z-index works */
  z-index: -1; /* Move behind clickable elements */
}

.showSafetyField {
  transition: opacity 0.5s ease-in-out, max-height 2s ease-in-out;
  opacity: 1;
  max-height: 2000vh;
  overflow: visible;
  pointer-events: auto; /* Allow interaction */
  position: relative; /* Ensure z-index works */
  z-index: 1; /* Bring in front */
}
