.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    background-color: #ba3428 !important;
}

.swal2-icon.swal2-error {
    border-color: #ba3428 !important;
}
.swal2-popup.swal2-toast .swal2-close {
    color: #f1e1c0;
}

.swal2-popup.swal2-toast .swal2-title {
    color: #fafaf6;
}
