.testimonial-card .card-up {
    height: 120px;
    overflow: hidden;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.fromCardGradient {
    background: linear-gradient(125deg, #6d002d, #30405c) !important;

}

.toCardGradient {
    background: linear-gradient(125deg, #30405c, #6d002d) !important;

}

.testimonial-card .avatar {
    width: 120px;
    margin-top: -60px;
    overflow: hidden;
    border: 5px solid #fff;
    border-radius: 50%;
}


/* RoundCard.css */
.round-card {
    width: 150px;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 50%;
    /* This makes it round */
    background-color: #f8f9fa;
}



.swapUserName {
    font-weight: bold !important;
    font-family: 'Times New Roman', Times, serif !important;
}

.swapUserRoleBadge {
    color: #ffffff !important;
    background-color: #9b9b9b !important;
    border: 2px dotted #d8d8d8 !important;
    border-radius: 0.5rem !important;
    line-height: 1.5 !important;
    font-size: 70% !important;
}

.hr_divider {
    border-top: 2px solid #3446541a !important;
}

.swapDateHead {
    width: 40% !important;
    background-color: #555555 !important;
    color: #ffffff !important;
    border: 1px solid #555555 !important;
}

.swapDateHead:hover {
    background-color: #850037 !important;
    color: #ffffff !important;
    border: 1px solid #850037 !important;
}

.swapDateBody {
    width: 60% !important;
    background-color: #ffffff !important;
    color: #555555 !important;
    border: 1px solid #555555 !important;
}

.expandButton {
    width: 100% !important;
    border-radius: 0 !important;
    background-color: #ffffff !important;
    color: #850037 !important;
}

.expandButton:hover {
    width: 100% !important;
    border-radius: 0 !important;
    background-color: #850037 !important;
    color: #ffffff !important;
}

.swappedShiftInfo {
    padding: 20px !important;
}

.swappedShiftInfo .ant-card-head-title {
    font-weight: 600 !important;
    text-align: center !important;
    font-size: 22px !important;
    color: #5c3f4b !important;
}